<template>
  <page-with-back-btn title="Safes & Accounts">
    <button id="modal-btn-2" type="button" style="display: none" data-bs-toggle="modal" data-bs-target="#staticExpensePaymentBackdrop1"></button>

    <div class="modal fade" id="staticExpensePaymentBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticExpensePaymentBackdropLabel1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="staticExpensePaymentBackdropLabel1">Create Safe Account</h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeExpenseModal1" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="safe-name">Safe Name:</label>
                    <input type="textfield" v-model="safeName" class="form-control" id="safe-name">
                  </div>
                </div>
                <div class="col-md-6"><div class="form-group mb-2">
                  <label for="paymenttype">Type</label>
                  <v-select
                      id="paymenttype"
                      v-model="type"
                      :options="['Cash Safe', 'Bank' ]"
                      :reduce="item => item"
                      label="Safe Type"
                  ></v-select>
                </div></div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-2">
                    <label for="safe-currency">Currency</label>
                    <v-select
                        id="safe-currency"
                        v-model="currency"
                        :options="storeCurrencies"
                        :reduce="currency => currency.Name"
                        label="Name"
                    ></v-select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-switch m-2">
                    <input v-model="isDefault" class="form-check-input" role="switch"
                           type="checkbox" id="spec-archived">
                    <label class="form-check-label" for="spec-archived">
                      Default
                    </label>
                  </div>
                </div>
              </div>
              <button type="submit" class="lyt-soft-btn my-2 w-100">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <MoveMoney class="m-2 mb-5"/>
    <MoveMoneyBetweenFranchises class="m-2 mb-5"/>
    <SafesTable/>
    <FloatingAddButton @click="createSafe" />
  </page-with-back-btn>
</template>

<script>
import PageWithBackBtn from "../../../components/layout/utils/PageWithBackBtn.vue";
import MoveMoney from "../../../components/Finance/MoveMoney.vue";
import SafesTable from "../../../components/Finance/SafesTable.vue";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import {Storage} from "@/Services/Storage";
import axios from "axios";
import MoveMoneyBetweenFranchises from "@/components/Finance/MoveMoneyBetweenFranchise.vue";

export default {
  name: "SafeBalances",
  components: {MoveMoneyBetweenFranchises, FloatingAddButton, SafesTable, MoveMoney, PageWithBackBtn},
  computed: {
    storeCurrencies() {
      return this.$store.getters.getStoreCurrencies || []; // Default to empty array
    },
  },
  data() {
    return {
      safeName: '',
      currency: '',
      type: '',
      isDefault: false,
    };
  },
  beforeMount() {
   },
  methods: {

    async submitForm() {
      this.$utils.showLoading();
      let storeDB = await Storage.getCurrentStoreDB();
      return axios.post(backOfficeTomcatApi + `chartOfAccounts/${storeDB}`, {
        safeName: this.safeName,
        type: this.type,
        currency: this.currency,
        isDefault: this.isDefault,
      }).then(() => {
        responses.showSuccess(`Done`);
        // Clear the input fields
        this.safeName = '';
        this.currency = '';
        this.type = '';
        this.isDefault = false;
      }).catch(error => errorHandler.tomcatError(error, false, "Failed to Create Safe"))
          .finally(() => this.$utils.hideLoading());
    },


    async createSafe() {

      const modalBtn = document.getElementById('modal-btn-2');
      if (modalBtn) {
        modalBtn.click();
      } else {
        console.error('Modal trigger button not found');
      }
    },
  }
}
</script>

<style scoped lang="scss">
li {
  margin-left: 10px;
}
.form-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}</style>