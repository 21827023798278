<template>
  <full-page title="Reports">
  <div class="scrollable-page">
    <div class="card-container">
      <div class="card" v-for="(card, index) in cards" :key="index" @click="handleClick(card.title)">
        <font-awesome-icon :icon="card.icon" class="card-icon" />
        <h3>{{ card.title }}</h3>
        <p>{{ card.content }}</p>
      </div>
    </div>
  </div>
  </full-page>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FullPage from "@/components/layout/utils/FullPage.vue";

export default {
  components: {
    FullPage,
    FontAwesomeIcon,
  },
  data() {
    return {
      cards: [
        { title: 'Dashboard', content: 'view overall performance of all stores.', icon: 'chart-simple' },
        { title: 'CashUp', content: 'View cashup reports for each store.', icon: 'gear' },
        { title: 'VAT', content: 'View VAT reports for each store.', icon: 'chart-bar' },
        { title: 'Supervisor', content: 'View supervisor reports for each store.', icon: 'users' },
        { title: 'Finance', content: 'view Finance reports for each store.', icon: 'users' },
        { title: 'Sales', content: 'View sales reports for each store.', icon: 'users' },
      ]
    };
  },
  methods: {
    handleClick(title) {
      alert(`You clicked on ${title}`);
    }
  }
};
</script>

<style scoped>
/* Ensures the entire page is scrollable */
.scrollable-page {
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 40px;
}

/* Card styling */
.card {
  border-radius: 20px;
  padding: 40px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 40px;
  margin-bottom: 20px;
  color: #4a4a4a;
}
</style>
