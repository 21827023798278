<template>
  <div class="success-container">
    <div class="success-card">
      <svg class="success-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="12" cy="12" r="10" />
        <path d="M9 12l2 2 4-4" />
      </svg>
      <h1>License Purchase Successful!</h1>
      <p>You have successfully added a new license. Thank you for your purchase.</p>
      <button class="btn btn-primary" @click="DownloadQuote">Download Quotation</button>
      <button class="btn2" @click="goHome">Return to Dashboard</button>
    </div>
  </div>
</template>

<script>
import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
  beforeMount() {
    this.getMainID();
  },
  data() {
    return {
      MainId: "",
      invnum: [],
    }
  },
  mounted() {
    const savedData = JSON.parse(localStorage.getItem('licenseData'));
    if (savedData) {
      this.numberOfDevices = savedData.numberOfDevices;
      this.quoteAmount = savedData.total;
      this.isAnnual = savedData.isAnnual;
      console.log('Loaded data from local storage:', savedData);
    }
  },
  methods: {
    goHome() {
      localStorage.removeItem('licenseData'); // Clear licenseData from local storage
      this.$router.push('/StoreManagements'); // Navigate to StoreManagement page
    },

    async getMainID() {
      try {
        this.$utils.showLoading();
        let DB = await Storage.getAllStores();
        let Store = DB[0].StoreDB;
        const response = await axios.get(
            franchiseTomcatApi + `stores/getRegistrationDetails?database=${Store}`
        );
        this.MainId = response.data.mainCustomerID; // Store user data
        console.log(this.MainId, "here is the MAINid");
      } catch (err) {
        errorHandler.tomcatError(err);
      } finally {
        this.$utils.hideLoading();
        await this.sendRoute()

      }
    },
    DownloadQuote() {
      this.$utils.showLoading();
      if (!this.invnum) {
        console.error("Invoice number is missing");
        this.$utils.hideLoading();
        return;
      }
      console.log(this.invnum, "Invoice number being used");
      this.$utils.hideLoading()
      openUrl(franchiseApi + `PDFReport/purchaseOrder/${this.invnum}/burntofi_chrilan`);

    },

    async sendRoute() {
      this.$utils.showLoading()
      let userlog = await Storage.getCurrentUser()
      const savedData = JSON.parse(localStorage.getItem('licenseData'));
      const payload = {
        mainCustomerID: this.MainId,
        user: userlog.Name,
        numberOfDevices:  parseInt(savedData.numberOfDevices, 10),
        total: savedData.total,
        isAnnual: savedData.isAnnual,
        storeDB: await Storage.getCurrentStoreDB()
      };

      this.newbtoa = btoa(JSON.stringify(payload))
      console.log(this.newbtoa)
      try {
        this.storeDB = await Storage.getCurrentStoreDB();

        const response = await axios.post(backoffice + `users/${this.storeDB}/buy-licence`, payload);
        console.log('Data sent successfully:', response.data);
        this.invnum = response.data.response.invoice;
      } catch (error) {
        return errorHandler.tomcatError(error)
      }
      finally {
        this.$utils.hideLoading()
      }
    },
  },
};
</script>

<style scoped>
.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f3f4f6;
}

.success-card {
  text-align: center;
  background: #fff;
  padding: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.success-icon {
  width: 80px;
  height: 80px;
  color: #4CAF50;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 2rem;
}

.btn:hover {
  background: blue;
}
.btn2 {
  padding: 0.75rem 1.5rem;
  background: #45A049;
   border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;}
.btn:hover {
  background: #45A049;
}
</style>
