<template>
  <div class="container-fluid navigation-bar p-1 px-2">
    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars" />
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box">
      <button v-if="isAndroid" @click="backToPOS" class="d-flex flex-row bg-transparent text-white fw-bolder">
        <font-awesome-icon icon="fingerprint" class="mx-2" />
        Back to POS
      </button>
    </div>
    <!-- Added Font Awesome Icon for Device Type -->

    <div class="user-settings my-2">
<!--      <button class="btn btn-info test" @click="openMessageAlert">-->
<!--        <font-awesome-icon icon="fa-solid fa-envelope" />-->
<!--       </button>-->
      <button @click="openSmartInvoice" v-if="(!isSmartInvoiced) && isSmartInvoiceLive" class="burger mx-2 red-blinking">
        <font-awesome-icon icon="triangle-exclamation" />
      </button>
      <a class="burger float-start mx-3" v-if="showLoyalty" @click="checkLoyalty">
        <font-awesome-icon icon="heart-circle-check" v-if="!!$store.getters.getLoyaltyDetails.barcode"/>
        <font-awesome-icon icon="heart-circle-plus" v-else/>
      </a>
      <Notification />

      <div class="device-type-icon my-2">
        <font-awesome-icon :icon="isMobile ? 'fa-solid fa-mobile-alt' : 'fa-solid fa-laptop'" />
      </div>

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
      </button>
    </div>
    <custom-modal title="Add Loyalty Customer To Sale" ref="scanLoyalty" @open="focusInput">
      <div v-if="!loyaltyDetails.barcode">
        <label for="loyalty-barcode">Scan Barcode</label>
        <input type="text" class="form-control" :disabled="$store.getters.getLoadingState" id="loyalty-barcode"
               v-model="loyaltyBarcode" ref="loyaltyCode"
               @keyup.enter="validateBarcode">
      </div>
      <div class="d-flex flex-column" v-else>
        <span class="text-sm text-secondary">Barcode : {{ loyaltyDetails.barcode }}</span>
        <div class="d-flex flex-row">
          Customer : {{ loyaltyDetails.name }} {{ loyaltyDetails.surname }}
          <a class="btn btn-sm btn-outline-primary mx-2" @click="$store.commit(`setLoyaltyDetails`,{})">
            <font-awesome-icon icon="close"/>
          </a>
        </div>
        <span>Contact : {{ loyaltyDetails.contact }}</span>
        Points Value : {{ loyaltyDetails.balance }} points - ({{ $utils.formatMoney(loyaltyDetails.pointsInCash) }})

      </div>
    </custom-modal>

    <custom-modal title="Smart Invoice Sign up Pending" ref="smartInvoiceModal">
      <div v-if="hasSubmittedDocuments">
        <p>Documents submitted successfully.</p>
        <button @click="viewTicket" class="btn btn-primary">View Ticket Status</button>
      </div>

      <div v-else class="row justify-content-center">
        <div class="col-md-8 text-center">
          <p class="mb-3">You have not registered for smart invoicing. Please sign up now:</p>
          <a href="#" class="btn btn-primary tub" @click="goToSmartInvoiceScreen">
            Sign up now
          </a>
        </div>
      </div>

    </custom-modal>
  </div>
</template>
<script>
import Notification from "@/components/layout/navigation/Notification";
import { Storage } from "@/Services/Storage";
import CustomModal from "@/components/layout/utils/CustomModal";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "NavigationBar",
  async created() {
    this.isMobile = JSON.parse(localStorage.getItem('isMobile')) || false;
    let isAndroid = await Storage.getAndroid();
    this.isAndroid = !!isAndroid && isAndroid !== "false";
  },
  data() {
    return {
      loyaltyBarcode: null,
      message: '',
      isMobile: false,
      isAndroid: false,
    };
  },
  components: { CustomModal, Notification },
  props: {
    toggle: {
      type: Function,
      require: true,
    },
  },
  async beforeMount() {
   },
  computed : {
    salesScreens() {
      return [`pos`, `payment`, `split-payment`, `instruction`]
    },
    showLoyalty() {
      let isSalesScreen = this.salesScreens.includes(this.$route.name);
      return this.$store.getters.getHasLoyalty && isSalesScreen;
    },
    loyaltyDetails() {
      return this.$store.getters.getLoyaltyDetails;
    },
    isSmartInvoiced(){
      return this.$store.getters.getSmartInvoicingStatus;
    },
    isSmartInvoiceLive(){
      return this.$store.getters.getSmartInvoicingLive;
    },
    hasSubmittedDocuments(){
      return this.$store.getters.getDocSubmissionStatus;
    },
  },
  methods: {
     openMessageAlert() {
      Swal.fire({
        title: "New Message",
        text: this.message,
        icon: "info",
        confirmButtonText: "Noted",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios.post(franchiseApi+`messages/mark-as-read/98998`, {
            messageReceived: true, // Customize payload as needed
          })
              .then(response => {
                console.log("Message acknowledged:", response.data);
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              })
              .finally(() =>
                  this.checkformessages()
              )
        }
      });
    },

    validateBarcode() {
      if (!isValidString(this.loyaltyBarcode)) return;
      this.$refs.scanLoyalty.closeModal();
      this.$utils.showLoading()
      return this.$store.dispatch(`checkLoyaltyBarcode`, this.loyaltyBarcode)
          .finally(() => {
            this.loyaltyBarcode = null;
            this.$refs.scanLoyalty.openModal();
          })
    },
    async focusInput() {
      if (!!this.loyaltyDetails.barcode) return;
      await new Promise(resolve => setTimeout(resolve, 1000));
      return this.$refs.loyaltyCode.focus();
    },
    checkLoyalty() {
      return this.$refs.scanLoyalty.openModal();
    },
    syncData() {
      this.$utils.showLoading();
      return window.location.reload(true);
    },
    backToPOS() {
      return openUrl(`https://android.abc.co.zm/`, `_self`);
    },
    openSmartInvoice() {
      return this.$refs.smartInvoiceModal.openModal();
    },

    closeModal() {
      return this.$refs.smartInvoiceModal.closeModal();
    },
    viewTicket() {
      this.closeModal();
      let id = this.hasSubmittedDocuments;
      return this.$router.push({ name : `TicketDetail` , params : {id} })
    },
    goToSmartInvoiceScreen() {
      this.closeModal();
      return this.$router.push({name : `smart-invoicing-setup`});
    }
  }
};
</script>
<style lang="scss" scoped>
.test {
   transition: all 0.3s ease-in-out;
  animation: blinker 1.5s infinite;

  &:hover {
    transform: scale(1.1);
    background-color: darken(#17a2b8, 10%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

button.btn {
  margin: 5px;
}
.device-type-icon {
  font-size: 24px;
  color: white;
}
.red-blinking {
  color: red;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

</style>
