import {Storage} from "../../../Services/Storage";
import axios from "axios";
import router from "../../../router";

export default {
    name: 'user',
    state: {
        allUsers: [],
        attemptedLoginRoute : { name : `stock` },

        currentUser: {}
    },
    getters: {
        getAllUsers: (state) => state.allUsers,
        getAttemptedRoute: (state) => state.attemptedLoginRoute,
        getCurrentUser: (state) => state.currentUser,
    },
    mutations: {
        setAllUsers: async (state, payload) => {
            if (payload) await Storage.setAllUsers(payload)
            else payload = await Storage.getAllUsers();
            state.allUsers = payload ? payload : [];
        },
        setAttemptedRoute: (state, data) => state.attemptedLoginRoute = data,
        setSessionID:async (state , data) => {
            if (data) await Storage.setSessionID(data)
            else data = await Storage.getSessionID();
            state.current_session = data;
        },
        setCurrentUser: async (state, payload) => {
            if (payload) await Storage.setCurrentUser(payload)
            else payload = await Storage.getCurrentUser();
            state.currentUser = payload ? payload : {};
        },
    },
    actions: {
        verifyOtp: (context, payload) => {
            console.log("Payload:", payload);

            // Send POST request to verify OTP
            return axios
                .post(ticketApi + "support/verifyOTP", payload)
                .then(async ({ data }) => {
                    console.log("Response Data:", data);

                    // Navigate to the route first
                    const route = { name: "stock" }; // Replace 'stock' with the desired route name
                    router.push(route);


                    // Perform the second POST request after navigation
                    const loggedUser = Storage.getItem("Supportlogged in user");

                    const storeDB = await Storage.getCurrentStoreDB();
                     const deviceID = await Storage.getDeviceID();

                    const loginPayload = {
                        username: loggedUser,
                        platform: "OnlineBackOffice",
                        pc: deviceID,
                    };

                    return axios.post(
                        `${serverApi}supervisorOverrides/${storeDB}/recordLogin`,
                        loginPayload
                    );
                })
                .catch((err) => {
                    console.error("Error during OTP verification:", err);
                    return errorHandler.tomcatError(err);
                });
        },

        // verifyOtp: (context, payload) => {
        //     console.log(payload)
        //     return axios.post(ticketApi + 'support/verifyOTP', payload)
        //         .then(({data}) => {
        //             context.commit(`setSessionID`,data)
        //             let route = context.getters.getAttemptedRoute;
        //             let routeName = route.name;
        //             const resolvedRoute = router.resolve({ name: routeName });
        //             let exists = resolvedRoute.route.name === routeName;
        //             if(!exists) route = { name : `stock` };
        //             return router.push(route);
        //         }).catch(err => errorHandler.tomcatError(err));
        // },
        getAllUsers: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `users/${storeDB}/getAll`)
                .then(({data}) => context.commit('setAllUsers', data))
                .catch(err => errorHandler.tomcatError(err))
        },
        createEmployee: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `employee/${storeDB}`, payload)
                .then(() => context.dispatch('getAllUsers'))
                .catch((err) => errorHandler.tomcatError(err))
        },
        createFranchiseEmployee: async (context, payload) => {
             return axios.post(backoffice + `api/franchise`, payload)
                 .catch((err) => errorHandler.tomcatError(err))
        },
        editEmployee: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseTomcatApi + `employee/${storeDB}/${payload.id}`, payload)
                .then(() => context.dispatch(`getAllUsers`))
                .catch((err) => errorHandler.tomcatError(err))
        },
        FranchiseeditEmployee: async (context, payload) => {
            const { idn, ...newPayload } = payload; // Remove 'idn' from the payload
            return axios.put(backoffice + `api/franchise/${idn}`, newPayload)
                .catch((err) => errorHandler.tomcatError(err));
        },


        deleteEmployee: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi + `employee/${storeDB}/${id}`)
                .then(() => context.dispatch(`getAllUsers`))
                .catch((err) => errorHandler.tomcatError(err))
        },
        deleteFranchsieEmployee: async (context, id) => {
             return axios.put(backoffice + `api/franchise/change-status/${id}?archive=true`)
                 .catch((err) => errorHandler.tomcatError(err))
        },
        validateUser2: async (context, password) => {
             // Retrieve all users from storage
            let users = await Storage.getAllUsers();
            // Find the user whose password matches
            let currentUser = users.find(user => user.password.toLowerCase() === password.toLowerCase());
            // Check if the user is found
            if (currentUser) {
                console.log(currentUser, "this is the current user")
                // Commit the user to the store
                await context.commit(`setCurrentUser`, currentUser);
                 return true;
            }

            // Return false if no user is found
            return false;
        },
        validateUser: async (context, password) => {
            let storeDB = await Storage.getCurrentStoreDB();

            if (!storeDB) return router.push({ name: 'login' });

            // Retrieve all users from storage
            let users = await Storage.getAllUsers();
            // Find the user whose password matches
            let currentUser = users.find(user => user.password.toLowerCase() === password.toLowerCase());

            // Check if the user is found
            if (currentUser) {
                // Commit the user to the store
                await context.commit(`setCurrentUser`, currentUser);

                // Check if this user is an "Admin" and has type "admin"


                // Continue with normal flow if conditions are not met
                context.dispatch(`checkSmartInvoicing`);
                return true;
            }

            // Return false if no user is found
            return false;
        }

    }
}
