import axios from "axios";
import {Storage} from "@/Services/Storage";


export default {
    name: 'Assets',
    state: {
        chartType : 'Asset'
    },
    getters: {},
    mutations: {},

    actions: {
        captureAssets: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            payload.type = context.state.chartType;
            $utils.showLoading();
            return axios.post(backOfficeTomcatApi +`assets/${storeDB}/create-record`,payload)
                .catch(err=> {
                    errorHandler.tomcatError(err)
                    $utils.hideLoading();
                });
        }

    }
}
