<template>
  <full-page title="Store Management">
    <div class="col m-3">
      <button class="btn btn-primary ml-2" style="display: none" @click="selectedView = 'login'">Login to Store</button>
      <button class="btn btn-primary ml-2" @click="LoginsTostore">Login to Store</button>
       <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">Update Store Details</button>
       <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal4">Buy a license</button>
       <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal4">Relay a message</button>
      <button type="button" id="openmodal" style="display: none"  class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>
     </div>
    <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Select Store</h1>
            <button id="close3" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 form-group text-center" >
              <label>
                Select Store to buy License for:
                <select v-model="currentStore" style="width: 245px;" class="form-select">
                  <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store.StoreName }}</option>
                </select>
              </label>
            </div>

            <button type="submit" v-if="!disableLogin" class="lyt-soft-btn h-50 w-100">Login</button>
            <button type="button" @click="selectStore32" v-else class="lyt-soft-btn h-50 w-100" >Select Store</button>              </div>
        </div>

      </div>
    </div>

    <!--modal to do stores store-->
    <div class="modal fade" id="exampleModalstore" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Select Store to send message from</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 form-group text-center" >
              <label>
                first login to store
                <select v-model="currentStoreselected" style="width: 245px;" class="form-select">
                  <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store?.StoreName }}</option>
                </select>
              </label>
            </div>

            <button type="submit" v-if="!disableLogin" class="lyt-soft-btn h-50 w-100">Login</button>
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal3" @click="selectStore3" v-else class="lyt-soft-btn h-50 w-100" >Select Store</button>              </div>
        </div>

      </div>
    </div>

    <!--    modal to update store end-->
<!--modal to update store-->
    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Select Store</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 form-group text-center" >
              <label>
                Select Store
                <select v-model="currentStore" style="width: 245px;" class="form-select">
                  <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store?.StoreName }}</option>
                </select>
              </label>
            </div>

            <button type="submit" v-if="!disableLogin" class="lyt-soft-btn h-50 w-100">Login</button>
            <button type="button" @click="selectStore2" v-else class="lyt-soft-btn h-50 w-100">Select Store</button>              </div>
        </div>

      </div>
    </div>
    <!-- Button trigger modal -->


    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
              Select option for: {{ selectedStore?.reference || 'N/A' }}
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <button class="btn my-2 btn-info" @click="Storestatistics">
              <font-awesome-icon class="icon" icon="fa-solid fa-chart-simple"/>
              Store Stats
            </button>
            <button class="btn my-2 btn-info" @click="storestats">
              <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
              Reset Stores
            </button>
<!--            <button class="btn my-2 btn-info" @click="sendMessage">-->
<!--              <font-awesome-icon icon="envelope" class="me-2"/>-->
<!--              Relay a Message-->
<!--            </button>-->
          </div>
        </div>
      </div>
    </div>
    <!--    modal to update store end-->
<!--    modal to add a store-->
    <div class="modal fade" id="Createstore" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="CreatestoreLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="CreatestoreLabel">
              <!-- Dynamically set the modal title based on step -->
              <template v-if="currentStep === 1">Select Store</template>
              <template v-if="currentStep === 2">Enter Password</template>
              <template v-if="currentStep === 3">Enter Storeroom Details</template>
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="currentStep === 1">
              <div class="mb-3 form-group text-center" >
                <label>
                  Select Store
                  <select v-model="currentStore" style="width: 245px;" class="form-select">
                    <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store?.StoreName }}</option>
                  </select>
                </label>
              </div>
              <button class="btn btn-primary mt-3 w-100" :disabled="!currentStore" @click="goToNextStep('select-store')">
                Next
              </button>
            </div>
            <div v-if="currentStep === 2">
              <form @submit.prevent="newStoreUserLogin" class="login-box ">
                <div class="mb-2 ">
                  <label class="d-flex flex-column justify-content-start align-items-start gap-2">
                    <label class="password-label">
                      <font-awesome-icon icon="fa-solid fa-lock" />
                      Password
                    </label>

                    <input
                        class="form-control"
                        placeholder="Password"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        autocomplete="current-password"
                        required
                    />
                  </label>
                </div>

                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="showPassword" v-model="showPassword" />
                  <label class="form-check-label" for="showPassword">Show Password</label>
                </div>
                <button type="submit" class="lyt-soft-btn h-50 w-100">Login</button>
              </form>
            </div>


            <form v-if="currentStep === 3" @submit.prevent="getStoreDetails">
              <div class="mb-2">
                <label for="store">Enter store name</label>
                <input class="form-control" id="store" v-model="forms.storeName" required>
              </div>
              <div class="mb-2">
                <label for="store">Enter store reference name</label>
                <input class="form-control" id="store" v-model="forms.storeReference" required>
              </div>
              <div class="mb-2">
                <label for="store">Enter store EmailAddress</label>
                <input class="form-control" id="store" v-model="forms.email" required>
              </div>
              <button :disabled="isSubmitting" type="submit" class="lyt-soft-btn h-50 w-100">Submit</button>
            </form>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                id="CreatestoreClose"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
<!--    modal to add a store end-->


<!--    modal to buy a lisence-->
    <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Buy License</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="quoteAmount !== null" class="alert alert-info">
              Amount to Pay: {{ quoteAmount }}
            </div>
            <form @submit.prevent="getQuote">
              <div class="mb-3">
                <label for="numberOfDevices" class="form-label">Number of Devices</label>
                <input type="number" v-model="numberOfDevices" class="form-control" id="numberOfDevices" required />
              </div>
              <div class="mb-3 form-check">
                <input type="checkbox" v-model="isAnnual" class="form-check-input" id="isAnnual" />
                <label class="form-check-label" for="isAnnual">Is Annual</label>
              </div>
              <button type="submit"  class="btn btn-primary w-100" v-if="!quoteAmount">Submit</button>
              <button type="button" class="btn btn-success w-100" v-else data-bs-toggle="modal" data-bs-target="#exampleModal5" @click="getMainID">Confirm</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--       modal to buy a new lisence for a store-->
    <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Select Store</h1>
            <button id="close3" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 form-group text-center" >
              <label>
                Select Store to buy License for:
                <select v-model="currentStore" style="width: 245px;" class="form-select">
                  <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store.StoreName }}</option>
                </select>
              </label>
            </div>

            <button type="submit" v-if="!disableLogin" class="lyt-soft-btn h-50 w-100">Login</button>
            <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal3" @click="selectStore3" v-else class="lyt-soft-btn h-50 w-100" >Select Store</button>              </div>
        </div>

      </div>
    </div>
    <!--       modal to buy a new lisence for a store username-->
    <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Enter Password</h1>
            <button id="close3" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="userLogin" class="login-box">
              <div class="col-12 text-center text-secondary">
              </div>
              <div class="form-group mb-2">
                <label >
                  <font-awesome-icon icon="fa-solid fa-lock" />
                  Password
                  <input
                      class="form-control"
                      placeholder="Password"
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      autocomplete="current-password"
                      required
                  />
                </label>
              </div>

              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" id="showPassword" v-model="showPassword" />
                <label class="form-check-label" for="showPassword">Show Password</label>
              </div>
              <button type="submit" class="lyt-soft-btn h-50 w-100">Login</button>
            </form>

          </div>
        </div>

      </div>
    </div>
    <div class="card m-3">
      <label class="card-header text-primary">
        <b style="text-underline: black">Stores</b>
      </label>
      <div class="card-body p-0">
        <vue-good-table
            v-if="selectedView === 'login'"
            :search-options="{ enabled: true }"
            :columns="columns"
            :rows="DB"
            :pagination-options="pageOptions"
            theme="polar-bear"
            styleClass="vgt-table condensed"
        >
          <template slot="table-row" slot-scope="props">
        <span @click="handleRowClick(props.row)" class="clickable-row">
          {{ props.formattedRow[props.column.field] }}
        </span>
          </template>
        </vue-good-table>

        <div v-else class="p-4 text-center">
          <p class="text-muted">No content available</p>
        </div>
      </div>
    </div>

    <FloatingAddButton data-bs-toggle="modal" data-bs-target="#Createstore" />
  </full-page>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FullPage from "@/components/layout/utils/FullPage.vue";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton.vue";
import { Storage } from "@/Services/Storage";
import axios from "axios";
import {Modal} from "bootstrap";

export default {
  components: {
    FullPage,
    FloatingAddButton,
    FontAwesomeIcon,
  },
  data() {
    return {
      currentStoreselected:[],

      store: '',
      currentStep:1,
      showPassword: false, // Add a data property for checkbox state
      password: "",
      newbtoa: "",
      forms: {
        storeName: '',
        storeReference: '',
        email: '',
      },
      form: {
        hasVat: false,
        allowBelowZero: false,
        useSeperateLine: false,
        storeName: null,
        storeAddress: null,
        gateEntry: false,
        storeAddress2: null,
        tpin: null,
        storeReference: null,
        contact: null,
        invoiceMessage: null,
        termsAndConditions: null,
        backOfficePrinter: null,
      },
      numberOfDevices: 0,
      isAnnual: false,
      MainId: "",
      localid: generateUUID(),
      isSubmitting: false,
      loggedin: { authorities: [] }, // Store user authorities
      permissionsStringArray: [],
      storeDetailsArray: [],
      roles: [],
      currentStore : null,
      quoteAmount: null,
      selectedView: 'login', // Default view is 'login' (shows table)
      columns: [
        { label: "Store Name", field: "storeName" },
        { label: "Address", field: "address" },
        { label: "Subscription Type", field: "type" },
        { label: "PCs", field: "NumberOfPC" },
        { label: "Annual Subscription", field: "Annual" },
        { label: "Expire Date", field: "expire" },
      ],
      pageOptions: {},
      DB: [],
      selectedStore: null,
    };
  },
  beforeMount() {
    // this.LoadPermissions();
    this.getMainID();
  },
  computed: {
    allStores() {
      return this.$store.getters.getAllStores;
    },
    disableLogin(){
      return (this.allStores && this.allStores.length > 0);
    },
  },
  methods: {
    selectStore234(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      document.getElementById("close3").click();
      return this.$store.dispatch(`selectstoretoadd`,this.currentStore)
          .finally(()=>{
            this.$router.push({ name: "Messages" });

            this.$utils.hideLoading()})
    },
    selectStore23(){
      this.store = this.currentStoreselected.StoreDB; // Correct syntax
      console.log(this.store)
    },
    LoginsTostore(){
      this.$router.push({name: 'login'})
    },
    selectStore2(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      return this.$store.dispatch(`loginToStoreFrmFranchise`,this.currentStore)
          .finally(()=>this.$utils.hideLoading())
    },
    async getStoreDetails() {
      this.$utils.showLoading();
      let currentStore = await Storage.getCurrentStore();

      return Promise.all([
        this.$store.dispatch('getCustomerDetails').then((store) => {
          // Store the entire 'store' object in the array
          this.storeDetailsArray.push(store);

          // You can still update your form fields here if needed
          this.form.storeName = store.CustomerName;
          this.form.contact = store.Cell;
          this.form.storeAddress = store.Addr1;
          this.form.storeAddress2 = store.Addr2;
          let tpin = store.Addr3.replaceAll('TPIN', '').trim();
          tpin = tpin.replaceAll(':','').trim();
          this.form.tpin = tpin;
          this.form.storeReference = currentStore.StoreName;
        }),
         ]).finally(() => {
        this.Createstoreroom()
         this.$utils.hideLoading();
      });
    },

    async Createstoreroom() {
      let forms = this.forms;
      this.storeDB = await Storage.getCurrentStoreDB();

      // Ensure that storeDetailsArray is not empty before accessing it
      if (this.storeDetailsArray.length > 0) {

        const storeDetails = this.storeDetailsArray[0];

        if(this.localid) {
          this.mainCustomerID = storeDetails.mainCustomerID
          console.log(this.mainCustomerID, "this is the maincustomer id")
           let tpinValue = storeDetails.Addr3?.replace(/^\D+/, "").trim(); // Remove non-digit prefix and trim spaces
          tpinValue = tpinValue.toLowerCase().replaceAll("tpin","");
          tpinValue = tpinValue.toLowerCase().replaceAll(":","");

          // Include store details from storeDetailsArray in the payload
          let obj = {
            ...forms,
            localid: this.localid,
            tpin: tpinValue, // Use the cleaned-up TPIN
            address: storeDetails.Addr1,
            Cell: storeDetails.Cell,
            address2: storeDetails.Addr2,
            annual: false,
            mainCustomer: storeDetails.mainCustomerID,
            deviceCount: 0,
            storeLocation: null,
            subscriptionType: 3,
            queryParams: {
              mainCustomerID: storeDetails.mainCustomerID,
            },
          };

          this.$utils.showLoading();
          const response =  await this.$store
              .dispatch("addStoreToCustomer", obj)
              .finally(() => this.$utils.hideLoading());
          if(response?.id) {
            responses.showSuccess('Store Created Successfully');
            // clear the form fields. and generate a new local id if the request is successful
            this.forms = {};
            // close the creat new store modal upon creation the store
            const modalElement = document.getElementById('Createstore');
            const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
            modalInstance.hide();
            this.localid = null;
          }
        }
      } else {
        console.error("Store details array is empty");
        this.isSubmitting = false;

      }
    },
    async newStoreUserLogin(){
      try {
        this.$utils.showLoading()
        await this.getQuote();
        const res = await this.$store.dispatch("validateUser2", this.password);
        if(res) {
          this.currentStep = 3;
        }else {
          responses.showInfo('Incorrect Password')
        }
      } catch (error) {
        // Handle any errors that occur during login
        console.error("Error during login process:", error);
        responses.throwErr("An error occurred. Please try again.");
      } finally {
        this.$utils.hideLoading()
      }
    },
    async goToNextStep(step) {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
      if(step && step === 'select-store' && this.currentStore) {
        this.currentStep = 2;
        return this.$store.dispatch(`selectstoretoadd`,this.currentStore)
            .finally(()=>{
              this.$utils.hideLoading()})
      }
    },
    selectStore32(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      document.getElementById("close3").click();
      return this.$store.dispatch(`selectstoretoadd`,this.currentStore)
          .finally(()=>{
            this.$router.push({ name: "Messages" });

            this.$utils.hideLoading()})

    },

    selectStore3(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      document.getElementById("close3").click();
      return this.$store.dispatch(`selectstoretoadd`,this.currentStore)
          .finally(()=>{
            this.$utils.hideLoading()})

    },
    async sendRoute() {
      this.$utils.showLoading()
      let userlog = await Storage.getCurrentUser()
      const payload = {
        mainCustomerID: this.MainId,
        user: userlog.Name,
        numberOfDevices: parseInt(this.numberOfDevices, 10),
        total: parseInt(this.quoteAmount, 10),
        isAnnual: this.isAnnual,
        storeDB: await Storage.getCurrentStoreDB()
      };
      this.newbtoa = btoa(JSON.stringify(payload))
      console.log(this.newbtoa)
    },
    async getQuote() {
      try {
        this.$utils.showLoading()
        this.storeDB = await Storage.getCurrentStoreDB();
        const response = await axios.get(
            backoffice + `users/${this.storeDB}/get-quote-amount?numberOfDevices=` + this.numberOfDevices + "&isAnnual=" + this.isAnnual
        );

        console.log("Full Response:", response); // Log full response
        console.log("Response Data:", response.data); // Log response data

        // Check if amount is inside response.data or another key
        this.quoteAmount = response.data.amount || response.data;

        console.log(this.quoteAmount, "amount to be paid");
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
      finally {
        this.$utils.hideLoading();
      }
    },
    makepayment() {
      const data = {
        numberOfDevices: this.numberOfDevices,
        total: parseInt(this.quoteAmount, 10),
        isAnnual: this.isAnnual,
      };

      localStorage.setItem('licenseData', JSON.stringify(data));
      console.log('Data saved to local storage:', data);
      const payload = {
        API_KEY: "42cd52f4-5081-11ee-92a6-00163e8d4996",
        amount: parseInt(this.quoteAmount, 10),
        Service_Description: "Purchase of extra license",
        Client_Refrence: this.newbtoa,
        Cancel_Url: "https://admin.chrilantech.com/UnsuccesfulPurchase",
        Sucess_Url: "https://admin.chrilantech.com/SuccessfulPurchace"
      };
      return axios.post(gateway + 'GateWay/CreateTransaction', payload)
          .then(({data}) => {
            return window.open(data.url,'_self');
          }).catch((err) => errorHandler.tomcatError(err));
    },
    async getMainID() {
      try {
        this.$utils.showLoading();
        let DB = await Storage.getAllStores();
        let Store = DB[0].StoreDB;
        const response = await axios.get(
            franchiseTomcatApi35 + `stores/getRegistrationDetails?database=${Store}`
        );
        this.MainId = response.data.mainCustomerID; // Store user data
        console.log(this.MainId, "here is the MAINid");
      } catch (err) {
        errorHandler.tomcatError(err);
      } finally {
        this.getStores();
        this.$utils.hideLoading();
      }
    },
    async LoadPermissions() {
      try {
        this.$utils.showLoading();
        let DB = await Storage.getAllStores();
        let Group = DB[0].Group;
       let mobileUser = await Storage.getMobileUser();
       console.log("here is the work, ",mobileUser )

        const response = await axios.get(backoffice + `api/franchise/role-permissions/${mobileUser.email}`);
        console.log(backoffice, "here is the backoffice");

        this.roles = response.data; // Store response in array
      }
      catch (err) {
        errorHandler.tomcatError(err)
      }
      finally{
        this.$utils.hideLoading()
      }
    },
    async getStores() {
      try {
        this.$utils.showLoading();
        const response = await axios.get(subscriptionTomcatApiforMainId + `customer/getStoresByCustomer?mainCustomerID=${this.MainId}`);

        // Ensure response contains data
        if (response.data && Array.isArray(response.data)) {
          this.DB = response.data; // Assign only the data array
        } else {
          console.error("Unexpected data format:", response.data);
          this.DB = []; // Reset to empty array if data isn't as expected
        }

        console.log(this.DB, "here is the information");
      } catch (err) {
        console.error("Error fetching stores:", err);
        this.DB = []; // Ensure it's always an array even on error
      } finally {
        this.$utils.hideLoading();
      }
    },

    async userLogin() {
      try {
        // 1. Submit the first request to validate the user with the password
        const res = await this.$store.dispatch("validateUser2", this.password);
      } catch (error) {
        // Handle any errors that occur during login
        console.error("Error during login process:", error);
        responses.throwErr("An error occurred. Please try again.");
      } finally {
        this.makepayment()
      }
    },
    handleRowClick(store) {
      this.selectedStore = store; // Save selected store
      document.getElementById("openmodal").click(); // Open modal
    },
    Storestatistics(){
      if (!this.selectedStore) {
        console.warn("No store selected!");
        return;
      }
      let id = this.selectedStore.id;
      let ref = this.selectedStore.reference;
      console.log("Selected Store:", id);
      this.$router.push({ name: "LoginStats", query: { id } });

    },
    sendMessage(){
      if (!this.selectedStore) {
        console.warn("No store selected!");
        return;
      }
      let id = this.selectedStore.id;
      let ref = this.selectedStore.reference;
      console.log("Selected Store:", id);
      this.$router.push({ name: "Messages", query: { id } });
    },

    storestats() {
      if (!this.selectedStore) {
        console.warn("No store selected!");
        return;
      }
      let id = this.selectedStore.id;
      let ref = this.selectedStore.reference;
      console.log("Selected Store:", id);
      this.$router.push({ name: "StoresList", query: { id } });
    },
  }
};
</script>

<style scoped>
.btn {
  min-width: 200px;
  margin: 5px;
}

.card {
  min-height: 200px;
  border-radius: 10px;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.clickable-row {
  cursor: pointer;
  color: blue;
   transition: background 0.2s ease-in-out;

  text-decoration: underline;
}
</style>
