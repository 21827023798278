<template>
  <div class="page">
     <button class="btn btn-primary top-left" @click="switchStore">
      <font-awesome-icon class="icon" icon="fa-solid fa-arrows-rotate"/>
      Switch Store
    </button>
     <button class="btn btn-primary top-left" @click="logOutOfFranchise">
      <font-awesome-icon icon="fa-solid fa-lock" />
      Log Out
    </button>

    <div class="cover-header">
      <img alt="" class="img-fluid" src="../assets/logo.png" />
    </div>
    <h3 class="text-white text-center">Lyt-Soft BackOffice</h3>
    <div class="login-box">
      <div class="col-12 text-center text-secondary">
        <h6>User Login</h6>

      </div>
      <form @submit.prevent="userLogin" >


      <div class="form-group mb-2">
        <label>
          <font-awesome-icon icon="fa-solid fa-lock" />
          Password
          <input
              class="form-control"
              placeholder="Password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="current-password"
              required
          />
        </label>
      </div>

      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="showPassword" v-model="showPassword" />
        <label class="form-check-label" for="showPassword">Show Password</label>
      </div>

      <div class="mb-3 form-group text-center">
        <a href="#"  @click="openResetPasswordAlert">Reset Password</a>
      </div>

      <button type="submit" class="login_btn">Login</button>
    </form>
  </div>
  </div>
</template>

<script>
import { Storage } from "@/Services/Storage";
import Swal from "sweetalert2";
import axios from "axios";
import router from "@/router";

export default {
  name: "UserLoginView",
  data() {
    return {
      localid: generateUUID(),
      message: '',
      password: "",
      showPassword: false, // Add a data property for checkbox state
    };
  },
  async beforeMount() {
   },
  methods: {
    async switchStore() {
        await this.$store.dispatch("clearStoreState");
        await this.$router.push({ name: "login" });
    },

     async userLogin() {
      // Show loading spinner

      try {
        // 1. Submit the first request to validate the user with the password
        const res = await this.$store.dispatch("validateUser", this.password);

        if (!res) {
          // If validation fails, show an error message
          responses.throwErr("Incorrect Password");
          return;
        }

        // 2. Get the current user from local storage
        const user = await this.getCurrentUser();

        if (!user) {
          responses.throwErr("User not found.");
          return;
        }
          console.log(user, "this is the current user being set")
        // 3. If the user is "Admin", redirect to verifySupportLogin page
        if (user.Username === "Admin" && user.Type === "admin") {
          return this.$router.push({ name: "verifySupportLogin" });
        }

        // 4. Validate security permission for other users
        const isAllowed = await this.$store.dispatch("validateSecurityPermission", "Boffice");

        if (isAllowed) {
          // 5. Show success message
          responses.showSuccess("Logged In Successfully");

          // 6. Redirect to the stock page
          this.$router.push({ name: "stock" });
          const storeDB = await Storage.getCurrentStoreDB();
          const loggedUser = await Storage.getCurrentUser();
          const deviceID = await Storage.getDeviceID();

          const loginPayload = {
            username: loggedUser.Name,
            platform: "OnlineBackOffice",
            pc: deviceID,
          };
           await axios.post(`${serverApi}supervisorOverrides/${storeDB}/recordLogin`,
              loginPayload);
        } else {
          responses.throwErr("Permission denied.");
        }
      } catch (error) {
        return errorHandler.tomcatError(error);

      } finally {
        // Ensure loading spinner is hidden
       }
    },
    async getCurrentUser() {
      try {
        // Retrieve the current user from local storage
        const user = await Storage.getCurrentUser();
        if (user) {
          return user;  // Return the user data
        } else {
          return null;
        }
      } catch (error) {
        return errorHandler.tomcatError(error);
       }
    },

    logOutOfFranchise() {
      return Swal.fire({
        title: "Are you sure?",
        text: "You are logging out of the Current Franchise",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#dd3",
        confirmButtonText: "Yes, Log Out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$utils.showLoading();
          let deviceID = await Storage.getDeviceID();
          localStorage.clear();
          await Storage.setDeviceID(deviceID);
          await this.$router.push({ name: "login" });
          return window.location.reload(true);
        }
      });
    },
    async openResetPasswordAlert() {
      // Prompt for email input
      const emailResult = await Swal.fire({
        title: "Reset Password",
        text: "Please enter your email address:",
        input: "email",
        inputPlaceholder: "Enter your email",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        inputValidator: (value) => {
          if (!value) {
            return "You need to enter an email address!";
          }
        },
      });

      if (!emailResult.value) return; // Stop if email is not provided

      try {
        let storeDb = await Storage.getCurrentStoreDB();

        // Generate a new localid before sending the reset request
        this.localid = generateUUID();

        // Send a POST request to initiate the reset process
        const response = await axios.post(backoffice + `auth/${storeDb}`, {
          localid: this.localid,
          email: emailResult.value,
        });

        if (response.status === 200) {
          await Swal.fire({
            title: "Email sent!",
            text: "We've sent a reset password link to your email.",
            icon: "success",
            confirmButtonText: "OK",
          });

          // Prompt for OTP input
          const otpResult = await Swal.fire({
            title: "Enter OTP",
            text: "Please enter the OTP sent to your email:",
            input: "text",
            inputPlaceholder: "Enter OTP",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
            inputValidator: (value) => {
              if (!value) {
                return "You need to enter the OTP!";
              }
            },
          });

          if (!otpResult.value) return; // Stop if OTP is not entered

          // Generate a new localid before submitting OTP
          this.localid = generateUUID();

          // Prompt for new password input
          const passwordResult = await Swal.fire({
            title: "Enter New Password",
            text: "Please enter your new password:",
            input: "password",
            inputPlaceholder: "Enter new password",
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonText: "Cancel",
            inputValidator: (value) => {
              if (!value) {
                return "You need to enter a new password!";
              }
            },
          });

          if (!passwordResult.value) return; // Stop if new password is not entered

          // Generate a new localid before sending final reset request
          this.localid = generateUUID();

          // Send OTP and new password to API
          const resetResponse = await axios.post(
              backoffice + `auth/${storeDb}/processResetPassword`,
              {
                localid: this.localid,
                otp: otpResult.value,
                newPassword: passwordResult.value,
              }
          );

          if (resetResponse.status === 200) {
            await Swal.fire({
              title: "Success",
              text: "Your password has been reset successfully.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              // Reload the page when "OK" is clicked
              location.reload();
            });
          } else {

          }
        } else if (response.status === 403) {
          return Swal.fire({
            title: "Wrong Email",
            text: "Please use the email which the company was registered with.",
            icon: "error",
          });
        } else {
         }
      } catch (error) {
        return errorHandler.tomcatError(error);

      }
    }
  },
};
</script>

<style>
body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}
</style>

<style lang="scss" scoped>
.page {
  height: 100vh;
}

.img-fluid {
  height: 250px;
  width: 260px;
  transition: 2s all ease-in-out;
  transform-origin: center;

  & :hover {
    transform: rotate(720deg);
  }
}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  border-radius: 0 0 15em 15em;
  /*background-image: url("../assets/images/bg.png");*/
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-height: 750px) {
  .cover-header {
    height: 46%;
  }
}

.login-box {
  position: absolute;
  bottom: 23%;
  right: 50%;
  transform: translate(50%, 38%);
  width: 90vw;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 1.5em;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);

  .form-group {
    position: relative;

    .icon {
      position: absolute;
      left: 10px;
      border-right: 1px solid rgba(222, 222, 222, 0.5);
      padding-right: 5px;
      top: 37px;
      color: rgba(200, 200, 200, 0.9);
      font-size: 1.6em;
    }
  }

  .form-control {
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0);
    display: block;
    margin: 5px auto;
    text-align: left;
    padding: 0 40px 0 50px;
    height: 40px;
    border-radius: 1em;
    outline: none;
    color: #a0a0a0;
    transition: ease-in-out 200ms;
  }

  .form-control:focus {
    background-color: transparent;
    font-weight: 400;
    outline: none;
  }

  select {
    width: 100%;
  }
  .top-left {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .user-settings {
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    justify-content: start;
  }
  .login_btn {
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    color: white;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 300;
    background: linear-gradient(205deg, #0b2550 0%, #295687 100%);
    border-radius: 1em;
    outline: none;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  .top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.3s ease-in-out;
    animation: pulse 1.5s infinite;

    &:hover {
      transform: scale(1.1);
      background-color: darken(#17a2b8, 10%);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  label {
    font-style: italic;
    font-weight: 500;
    font-size: 1em;
    color: #6c757d !important;
    margin-bottom: 0;
    margin-left: 20px;
  }
}
</style>
