<template>
  <full-page title="Tickets">
    <RangeSelector :submit-method="searchTickets"/>
    <div class="card p-2 mx-3">
      <div class="row">
        <div class="col-md-4">
          <label for="ticketTitle">Title / Description</label>
          <input class="form-control" type="text" id="ticketTitle" v-model="form.title">
        </div>

        <div class="col-md-4">
          <label for="ticketType" class="fw-bold">Type</label>
          <v-select id="ticketType" :reduce="item=>item" v-model="form.type" label="Type"
                    :options="ticketTypes">
            <template slot="no-options">
              <b>Type to search ...</b>
            </template>
          </v-select>
        </div>

        <div class="col-md-4">
          <label for="ticketLimit">Limit</label>
          <input class="form-control" type="text" id="ticketLimit" v-model="form.limit">
        </div>
      </div>
      <div class="form-switch mt-1" style="display: none">
        <input class="form-check-input" role="switch" v-model="form.allStores"
               type="checkbox"
               id="all-stores">
        <label class="form-check-label" for="all-stores">
          Show for Entire Franchise
        </label>
      </div>
    </div>
    <div class="card m-3 ">
      <div class="card-header text-primary">
        <b style="text-underline: black"> All Tickets</b>
      </div>

      <div class="card-body p-0">
        <b-tabs v-model="activeTab">
          <b-tab title="Open Tickets" @click="setActiveTab(0)">
            <vue-good-table :search-options="{ enabled: true }" :columns="columns" :rows="filteredTickets"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'title'">
                  <div class="text-secondary text-sm">#{{ props.row.id }}</div>
                  <span style="font-size: 13px">{{ props.row.title }}</span>
                </div>
                <div v-else-if="props.column.field === 'store'">
                  <span class="d-flex flex-column">{{ props.row.store?props.row.store:`No Store Specified` }}</span>
                </div>

                <div v-else-if="props.column.field === 'status'">
                  <span class="badge bg-warning">{{ props.row.status }}</span>
                </div>

                <div v-else-if="props.column.field === 'action'">
                  <button class="btn btn-sm btn-primary" @click="viewTicket(props.row.id);  viewTicket2(props.row.storeDB)">
                    View
                    </button>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
          <b-tab title="Closed Tickets" @click="setActiveTab(1)">
            <vue-good-table :search-options="{ enabled: true }" :columns="columns" :rows="filteredTickets"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'title'">
                  <div class="text-secondary text-sm">#{{ props.row.id }}</div>
                  <span style="font-size: 13px">{{ props.row.title }}</span>
                </div>
                <div v-else-if="props.column.field === 'store'">
                  <span class="d-flex flex-column">{{ props.row.store?props.row.store:`All` }}</span>
                </div>

                <div v-else-if="props.column.field === 'status'">
                  <span class="badge bg-success">{{ props.row.status }}</span>
                </div>

                <div v-else-if="props.column.field === 'type'">
                  <span class="d-flex flex-column">
                    <span class="text-sm">{{ props.row.category }}</span>
                    <span class="text-sm">{{ props.row.subCategory }}</span>
                  </span>
                </div>

                <div v-else-if="props.column.field === 'action'">
                  <button class="btn btn-sm btn-primary" @click="viewTicket2(props.row.storeDB) ">
                    View
                   </button>
                </div>
              </template>
            </vue-good-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>
   </full-page>
</template>

<script>
import axios from 'axios';
import FullPage from "@/components/layout/utils/FullPage.vue";
import RangeSelector from "@/components/layout/utils/RangeSelector";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import {Storage} from "@/Services/Storage";

export default {
  name: "Tickets",
  components: {FloatingAddButton, RangeSelector, FullPage},
  data() {
    return {
      activeTab: 0, // Default to 'open' tab, set to number
      columns: [
        {label: "Title", field: "title", width: `350px`},
        {label: "Store", field: "store", width: `300px`},
        {label: "Status", field: "status"},
         { label: "Created Date", field: "createdDate", },
         { label: "Action", field: "action", },
       ],
      pageOptions,
      tickets: [], // This will contain all tickets, both open and closed
      form: {
        title: null,
        limit: 50,
        type: null,
        allStores: true,
      }
    };
  },
  computed: {
    filteredTickets() {
      return this.tickets;
    },
    ticketTypes() {
      return [
        `error`,
        `featureRequest`,
        `billing`,
        `consultation`,
        `calloutRequest`,
      ]
    },
  },
  methods: {
    selectStore(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      return this.$store.dispatch(`loginToStore`,this.currentStore)
          .finally(()=>this.$utils.hideLoading())
    },
    async viewTicket2(id) {
      this.$utils.showLoading();
      try {
        let stores = await Storage.getAllStores(); // Fetch all stores
        console.log("All Stores:", stores);

        let selectedStore = stores.find(store => store.StoreDB === id); // Find store by ID
      console.log(selectedStore, "here is everything")
        if (selectedStore) {
           return this.$store.dispatch(`loginToStore2`,selectedStore)
        } else {
          console.warn("Store not found for ID:", id);
          return null;
        }
      } catch (error) {
        console.error("Error fetching stores:", error);
        return null;
      } finally {
        this.$utils.hideLoading();
      }
    },

    setActiveTab(tab) {
      this.activeTab = tab;
      this.searchTickets();
    },
    viewTicket(id) {
      let ticketid = id;
      localStorage.setItem("ticketID", ticketid); // Store the ticket ID
    },

    async searchTickets() {
      try {
        let DB = await Storage.getCurrentStoreDB();

        if (!DB) {
          Swal.fire({
            title: "First login before proceeding",
            text: "Please click the button below to Enter ones stores information before proceeding to view tickets.",
            icon: "warning",
            confirmButtonText: "Go to Store Selection",
          }).then(() => {
            this.$router.push("/SelectStore");
          });
          return;
        }

        DB = window.btoa(DB);
        let config = {
          headers: {
            DB,
          },
          params: {
            ...this.$store.getters.getGlobalDateRange,
            closed: this.activeTab !== 0,
            ...this.form,
          },
        };

        this.$utils.showLoading();
        const response = await axios.get(ticketApi + 'ticket/searchByStore', config);
        this.$utils.hideLoading();
        this.tickets = response.data;
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },
  },
  mounted() {
    this.searchTickets();
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
.text-sm {
  font-size: 13px;
}
</style>