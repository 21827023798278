<template>
  <page-with-back-btn title="Edit Franchise Employee">
    <div class="list-body">
      <form class="row" @submit.prevent="editEmployee">

        <div class="col-md-4">
          <div class="form-floating">
            <select class="form-select" required v-model="employee.roleId" id="security-group">
              <option v-for="option in roles" :key="option.id" :value="option.id">
                {{ option.role }}
              </option>
            </select>
            <label for="security-group">Security Group</label>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-floating">
            <input id="emp-email" type="text" placeholder="Employee Email"
                   class="form-control" v-model="employee.email" :disabled="doNotUseEmail">
            <label for="emp-email">Email</label>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-floating">
            <input id="emp-password" :type="showPIN" placeholder="Employee Password"
                   class="form-control" required autocomplete="off" v-model="employee.password">
            <label for="emp-password">Password</label>
          </div>
        </div>
        <label class="show-password-checkbox my-2">
          <input type="checkbox" v-model="show_password">
          Show Password
        </label>
        <div class="container mt-4">
          <button :disabled="loading" type="submit" class="primary-button w-100">
            <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            SAVE
          </button>
        </div>
      </form>
    </div>
  </page-with-back-btn>
</template>

<script>
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn.vue";
import {Storage} from "@/Services/Storage";

export default {
  name: "FranchiseEmpEditView",
  components: {PageWithBackBtn},
  async beforeMount() {
    this.getallroles();
    await this.users(); // Ensure users() finishes first
    await this.getEmployee(); // Then fetch employee data
  },

  data() {
    return {
      employee: {
        roleId: "",
        password: "",
        group: "",
        email: "",
        franchise: true,
      },
      doNotUseEmail: false,
      roles: [],
      loading: false,
      currentpassword: '',
      password_confirm: '',
      show_password : false,
    }
  },
  computed: {
    allStores(){
      return this.$store.getters.getAllStores;
    },
    showPIN(){
      return this.show_password?`text`:`password`
    },
  },
  methods: {
    async getallroles() {
      this.$utils.showLoading();
      console.log(this.allStores, 'hello world')
      let DB = await Storage.getAllStores();
      let Group = DB[0].Group;
      axios.get(backoffice + `api/roles?group=${Group}`)
          .then(response => {
            if (response.data) {
              this.roles = response.data; // Ensure response structure matches expected data
            }
          })
          .catch(error => {
            console.error("Error fetching roles:", error);
          })
          .finally(() => {
            this.$utils.hideLoading();
          });
    },

    async users() {
      this.$utils.showLoading();
      try {
        if (this.allStores.length > 0) {
          this.group = this.allStores[0].Group;
        } else {
          this.group = ""; // Handle case where array is empty
        }

        // Fetch data from API
        const response = await axios.get(backoffice + `api/franchise?group=${this.group}`);

        // Ensure response contains data before assigning
        if (response.data) {
          this.res = response.data;  // Assign response to res
        } else {
          this.res = []; // Default to empty array if no data is returned
        }

        console.log("Fetched users:", this.res);
      } catch (err) {
        errorHandler.tomcatError(err);
      } finally {
        this.$utils.hideLoading();
      }
    },
    async getEmployee() {
      try {
        this.$utils.showLoading();

        // Wait for data to be available
        if (!this.res || this.res.length === 0) {
          console.warn("No employee data available yet.");
          return;
        }

        // Find the employee by ID
        const employeeData = this.res.find(u => u.id === this.$route.query.id);
        if (!employeeData) {
          console.warn("Employee not found with ID:", this.$route.query.id);
          return;
        }

        // Assign the found employee to the local state
        this.employee = {
          idn: this.$route.query.id,
          password: employeeData.password || "",
          roleId: employeeData.role || "",
          email: employeeData.email || "",
          group: employeeData.group || "",
          franchise: true,
        };
      } catch (err) {
        console.error("Error fetching employee:", err);
      } finally {
        this.$utils.hideLoading();
      }
    },
    back() {
      this.$router.back();
    },

    editEmployee() {
      this.$utils.showLoading()
      if (this.allStores.length > 0) {
        this.employee.group = this.allStores[0].Group;
      } else {
        this.employee.group = ""; // Handle case where array is empty
      }
      return this.$store.dispatch('FranchiseeditEmployee', this.employee).then(() => {
        responses.showSuccess('employee Edit Success')
       this.$router.back();
      })
    },
  }

}
</script>

<style lang="scss" scoped>
.list-body {
  padding: 10px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.col-md-4 {
  margin: 5px 0 0;
}
</style>
