<template>
  <div class="m-2">

    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Safe and Account Options</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- "View Details" button triggers openSafe with the selectedChartID -->
            <div class="row mb-2">
              <button class="btn btn-secondary" @click="openSafe2(chartID)">View Details</button>
            </div>
            <div class="row mb-2">
              <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Editmodalsafe" @click="">Update</button>
            </div>
            <div class="row">
              <button class="btn btn-danger" @click="confirmDelete">Delete</button>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for editing the safe/Account -->
    <div class="modal fade" id="Editmodalsafe" tabindex="-1" aria-labelledby="Editmodalsafe" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="Editmodalsafe">Edit Safe and Account</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitUpdate">
              <div class="form-group mb-2">
                <label for="safe-name">Safe Name:</label>
                <input type="textfield" v-model="safeName" class="form-control" id="safe-name">
              </div>
              <div class="form-group mb-2">
                <label for="paymenttype">Type</label>
                <v-select id="paymenttype" v-model="type" :options="['Cash Safe', 'Bank']" :reduce="item => item" label="Safe Type"></v-select>
              </div>
              <div class="form-group mb-2">
                <label for="safe-currency">Currency</label>
                <v-select id="safe-currency" v-model="currency" :options="storeCurrencies" :reduce="currency => currency.Name" label="Name"></v-select>
              </div>
              <div class="form-switch m-2">
                <input v-model="isDefault" class="form-check-input" role="switch" type="checkbox" id="spec-archived">
                <label class="form-check-label" for="spec-archived">Default</label>
              </div>
              <button type="submit" class="lyt-soft-btn my-2 w-100">Submit</button>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>



    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button @click="getCashSafes" class="nav-link active btn-outline-info" id="cashsafes-tab" data-bs-toggle="pill" data-bs-target="#cashsafes" type="button" role="tab">
          Cash Book
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button @click="getBankAccounts" class="nav-link btn-outline-info" id="bank-tab" data-bs-toggle="pill" type="button" role="tab">
          Bank / Virtual Accounts
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button @click="getChequeAccount" class="nav-link btn-outline-info" id="cheque-tab" data-bs-toggle="pill" data-bs-target="#cheque" type="button" role="tab">
          Cheque Accounts
        </button>
      </li>
    </ul>

    <div class="card m-3">
      <label class="card-header text-primary">
        <b style="text-underline: black">Safes & Virtual Accounts</b>
      </label>
      <div class="form-switch float-start">
        <input
            class="form-check-input mx-1"
            role="switch"
            v-model="showArchived"
            @change="handleArchiveToggle"
        type="checkbox"
        name="flexRadioDefault"
        id="archived"
        />
        <label class="form-check-label" for="archived">Archived</label>
      </div>
      <div class="card-body p-0">
        <vue-good-table :search-options="{ enabled: true }" :columns="columns" :rows="Safes" :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
          <template slot="table-row" slot-scope="props">
            <div v-if="props.column.field === 'Balance'">
              <span>{{ $utils.formatCurrency(props.row.Balance, 2, props.row.symbol) }}</span>
            </div>
            <div v-else-if="props.column.field === 'action'">
              <!-- Updated button to open modal and set selectedChartID -->
              <button class="btn btn-primary w-75" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="openSafe(props.row.id)">
                <font-awesome-icon icon="fa-solid fa-eye" />
                View
              </button>
            </div>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
  name: 'SafesTable',
  components: { FloatingAddButton },
  beforeMount() {
    this.getCashSafes();
  },
  computed: {
    storeCurrencies (){
      return this.$store.getters.getStoreCurrencies;
    },
    Safes() {
      return this.$store.getters.getSafes;
    },
    safeTotal() {
      let sum = 0;
      for (let i = 0; i < this.Safes.length; i++) {
        sum += parseFloat(this.Safes[i].Balance);
      }
      return this.$utils.formatMoney(sum);
    },
  },
  data() {
    return {
      safeName: '',
       activeTab: 'CASH', // Track the active tab
      currency: '',
      type: '',
      isDefault: false,
      columns: [
        { field: 'id', label: 'ID' },
        { field: 'ChartName', label: 'Account Name' },
        { field: 'Balance', label: 'Account Balance', type: 'number' },
        { field: 'action', label: 'Action', sortable: false },
      ],
      pageOptions: pageOptions,
      showArchived: false,
      chartID: null,
      selectedChartID: null, // Store the ID of the item to view in modal
      selectedSafe: null, // Store selected safe/account data for editing
    };
  },
  methods: {
    handleArchiveToggle() {
      // Check which tab is active and call the respective method
      if (this.activeTab === 'CASH') {
        this.getCashSafes();
      } else if (this.activeTab === 'BANK') {
        this.getBankAccounts();
      } else if (this.activeTab === 'CHEQUE') {
        this.getChequeAccount();
      }
    },
    openSafe2(chartID) {
      return this.$router.push({name: 'view-safe', query: {chartID: chartID}})
    },
    openSafe(chartID) {
      // Find the selected safe data from the Safes array
      this.selectedSafe = this.Safes.find(safe => safe.id === chartID);
      this.chartID = chartID; // Set selected ID


      if (this.selectedSafe) {
        // Prefill the modal fields with the selected safe data
        this.safeName = this.selectedSafe.ChartName;
        this.currency = this.selectedSafe.Currency;
        this.type = this.selectedSafe.PrimaryBank ? 'Bank' : 'Cash Safe';
        this.isDefault = this.selectedSafe.PrimaryCash;
      }
    },
    async submitUpdate() {
      this.$utils.showLoading();
      let Cuser = await Storage.getCurrentUser();
      // Get the current store DB and add it along with chartID in params
      let storeDB = await Storage.getCurrentStoreDB();
      return axios.put(`${backOfficeTomcatApi}safeFunctions/${storeDB}/updateSafe?chartID=${this.selectedSafe.id}`, {
        safeName: this.safeName,
        type: this.type,
        user: Cuser.Username,
        currency: this.currency,
        isDefault: this.isDefault,
      })
          .then(() => {
            responses.showSuccess('Done');

            // Clear input fields after submission
            this.safeName = '';
            this.currency = '';
            this.type = '';
            this.isDefault = false;
            this.getCashSafes();
          })
          .catch(error => errorHandler.tomcatError(error, false, 'Failed to Update Safe'))
          .finally(() => this.$utils.hideLoading());
    },
    confirmDelete() {
      // Use SweetAlert to confirm deletion
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Call delete method if confirmed
          this.deletrecord();
        }
      });
    },
    async deletrecord() {
      this.$utils.showLoading();

      let storeDB = await Storage.getCurrentStoreDB();
      return axios.post(`${backOfficeTomcatApi}safeFunctions/${storeDB}/archiveSafeDetails?${storeDB}&chartID=${this.selectedSafe.id}`)
          .then(() => {
            responses.showSuccess('Deleted successfully');
          })
          .catch(error => errorHandler.tomcatError(error, false, 'Failed to delete Safe'))
          .finally(() => this.$utils.hideLoading());
    },

    getCashSafes() {
      this.activeTab = 'CASH'; // Update active tab
      this.$utils.showLoading();
      return this.$store.dispatch('getSafesByTpe', { type: 'CASH', archive: this.showArchived })
          .finally(() => this.$utils.hideLoading());
    },
    getBankAccounts() {
      this.activeTab = 'BANK'; // Update active tab
      this.$utils.showLoading();
      return this.$store.dispatch('getSafesByTpe', { type: 'BANK', archive: this.showArchived })
          .finally(() => this.$utils.hideLoading());
    },
    getChequeAccount() {
      this.activeTab = 'CHEQUE'; // Update active tab
      this.$utils.showLoading();
      return this.$store.dispatch('getSafesByTpe', { type: 'CHEQUE', archive: this.showArchived })
          .finally(() => this.$utils.hideLoading());
    },

    createSafe() {
      return this.$router.push({ name: 'DebtorSafe' });
    }
  },
};
</script>

<style scoped lang="scss">
li {
  margin-left: 10px;
}

.table-responsive {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
