<template>
  <div>
    <button type="button" id="poActionsBtn" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="`#poActions-${purchaseOrder.CurrentSale}`">
      <font-awesome-icon icon="pen-to-square" />
      View
    </button>
    <!-- Button trigger modal -->
    <button class="btn btn-primary" ref="openmen" id="openmen" data-bs-toggle="modal" data-bs-target="#exampleModal11" style="display: none;">click</button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal11" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Send Invoice number: {{purchaseOrder.CurrentSale}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">


            <div class="d-flex flex-column">
              <div v-for="(input, index) in additionalInputs" :key="index" class="mb-3">
                <label class="w-100">
                  Email {{ index + 1 }}
                  <a class="btn btn-sm btn-outline-primary mx-2" @click="addInput"
                     v-if="index === 0 && additionalInputs.length < 5">
                    <font-awesome-icon icon="plus"/>
                  </a>

                  <a class="btn btn-sm btn-outline-danger" @click="removeInput(index)" v-if="index !== 0">
                    <font-awesome-icon icon="minus"/>
                  </a>
                  <input v-model="additionalInputs[index]" type="email" class="form-control" required
                         :placeholder="`Email ${index + 1}`">
                </label>
              </div>
              <div class="d-flex flex-column mb-2">

              </div>
              <div class="mb-3">
                <label class="w-100">
                  Additional Notes
                  <textarea class="form-control" v-model="form.additionalNote" rows="7" placeholder="Add notes"></textarea>
                </label>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" @click="sendDocs">submit Email</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" :id="`poActions-${purchaseOrder.CurrentSale}`" tabindex="-1" :aria-labelledby="`poActions-${purchaseOrder.CurrentSale}Label`" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="`poActions-${purchaseOrder.CurrentSale}Label`">Delivery Note No. : {{ purchaseOrder.CurrentSale }} </h1>
            <button type="button" :id="`poActions-${purchaseOrder.CurrentSale}-close`"  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Customer : {{ purchaseOrder.Name }}</p>
            <p>Created By : {{purchaseOrder.User}}</p>
            <p>Total : {{ $utils.formatCurrency(purchaseOrder.Total , 2 , purchaseOrder.Symbol) }}</p>

            <div class="d-flex flex-column">

              <button class="btn my-2 btn-primary"  @click="emailPO">
                <font-awesome-icon icon="envelope" />
                Send Email
              </button>

              <button class="btn my-2 btn-danger" v-if="!purchaseOrder.processed" @click="DeletePurchaseOrder">
                <font-awesome-icon icon="trash" />
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomModal from "@/components/layout/utils/CustomModal";
export default {
  name: "DeliveryNotesOptions",
  components: {CustomModal},
  data() {
    return {
      form: {
        additionalNote : ``
      },
      additionalInputs: [``]
    };
  },
  props: {
    purchaseOrder : {
      type : Object,
      required : true
    }
  },
  methods : {
    addInput() {
      if (this.additionalInputs.length >= 5) return responses.showWarning(`Max emails reached!`)
      this.additionalInputs.push('');
    },

    removeInput(index) {
      this.additionalInputs.splice(index, 1);
    },
    emailPO(){
      this.form.additionalNote = `Supplier : ${this.purchaseOrder.Name}\n`
           +`Created By : ${this.purchaseOrder.User}\n`
          +`Created on : ${this.purchaseOrder.Date}\n`
           +`Purchase Total : ${$utils.formatCurrency(this.purchaseOrder.Total,2,this.purchaseOrder.Symbol)}`;
      this.$refs.openmen.click();
    },
    sendDocs() {
      let obj = {
        emails: this.additionalInputs,
        id: this.purchaseOrder.CurrentSale,
        documentType: "deliveryNote",
        additionalNote : this.form.additionalNote
      }
      this.$utils.showLoading()
      return this.$store.dispatch(`sendEmailDocument`, obj)
          .then(() => {
            let email = ``;
            this.additionalInputs = [email];
            this.form.additionalNotes = ``;
            responses.showInfo(`Email(s) Send Successfully`)
          }).finally(() => this.$utils.hideLoading())
    },

    closeModal(){
      document.getElementById(`poActions-${this.purchaseOrder.CurrentSale}-close`).click();
    },

    async DeletePurchaseOrder(){
      let CurrentSale = this.purchaseOrder.CurrentSale;
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'viewPurchaseOrder');
      if (!isAllowed) return;
      return Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Delivery Note!",
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        buttonsStyling: false, // Disabling default button styling
        showLoaderOnConfirm : true,
        customClass: {
          confirmButton: "btn btn-danger mx-2", // Custom styling for confirm button
          cancelButton: "btn btn-secondary", // Custom styling for cancel button
        },

        // dangerMode: true,
        preConfirm:()=> {
          return this.$store.dispatch(`deleteDeliveryNote`,CurrentSale)
              .then(() => {
                this.closeModal();
                this.$emit(`updated`)
              })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          return responses.showInfo(`Done`)
        }
      });
    },
  }
}
</script>

<style scoped>

</style>