<template>
  <div id="app">
    <NetworkStatus />

    <!-- Ensure Loader component is correctly placed -->
    <Loader />
    <router-view/>
  </div>
</template>

<script>
import Loader from "@/components/layout/Loader";
import NetworkStatus from "@/components/layout/NetworkStatus.vue";

export default {
  components: {  Loader, NetworkStatus },
  beforeMount() {
    this.validatePCRegistration();
  },
  methods: {
    validatePCRegistration() {
      return this.$store.dispatch('refreshState');
    }
  }
}
</script>

<style lang="scss">
@import "assets/css/styles";

.accordion-button:active {
  transform: scale(1) !important;
}
</style>
