<template>
  <page-with-back-btn title="Login Stats">
    <RangeSelector :submit-method="searchPurchaseOrders"/>
    <div class="m-3">
      <form @submit.prevent="searchPurchaseOrders">
        <div class="row">
          <button type="button" id="hidden-modal-button" class="btn btn-primary visually-hidden" data-bs-toggle="modal"
                  data-bs-target="#exampleModal">
            Launch demo modal
          </button>

          <div class="col-md-4">
            <label for="float-user">User</label>
            <v-select
                id="float-user"
                v-model="form.user"
                :options="$store.getters.getAllUsers"
                :reduce="item => item.Username"
                label="Username"
            ></v-select>
          </div>
          <div class="col-md-4">
            <label for="search-limit">Limit</label>
            <input type="number" v-model="form.limit" placeholder="Limit" id="search-limit" class="form-control">
          </div>
         </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary w-75" type="submit">Search</button>
        </div>
      </form>

    </div>
    <!-- Modal to edit customer stuff-->
     <div class="card m-3">
      <label class="card-header text-primary">
        <b style="text-underline: #000000">Login Stats</b>
      </label>
      <div class="card-body p-0">
        <vue-good-table :search-options="{ enabled : true }" :columns="columns" :rows="purchaseOrder"
                        :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
          <template slot="table-row" slot-scope="props">
           </template>
        </vue-good-table>
      </div>
    </div>
  </page-with-back-btn>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage";
import RangeSelector from "@/components/layout/utils/RangeSelector";
import axios from "axios";
import {Storage} from "@/Services/Storage";
import PurchaseOrderOptions from "@/components/Stock/PurchaseOrders/PurchaseOrderOptions";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import CustomModal from "@/components/layout/utils/CustomModal";
import DeliveryNotesOptions from "@/views/Debtors/DeliveryNotes/DeliveryNotesOptions.vue";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn.vue";

export default {
  name: "PurchaseOrdersView",
  components: {
    PageWithBackBtn,
    DeliveryNotesOptions, CustomModal, FloatingAddButton, PurchaseOrderOptions, RangeSelector, FullPage},
  beforeMount() {
    this.searchPurchaseOrders();
    this.getStoreDB()

  },
  computed: {
    totalPurchaseOrderCost() {
      let total = 0;
      this.purchaseOrder.forEach(order => {
        let currencies = this.$store.getters.getStoreCurrencies;
        let poCurrency =currencies.find(cur=>cur.Symbol === order.Symbol);
        if (!poCurrency) poCurrency = this.$store.getters.getBaseCurrency;
        let convertedTotal = order.Total * poCurrency.Rate;
        total += convertedTotal;
      });
      return this.$utils.formatMoney(total);  // Format to 2 decimal places
    }
  },
  data() {
    return {
      limit: 10,
      datab: [],
       suppliers: [],
      purchaseOrder: [],
      columns: [
        {
          label: "PC",
          field: "PC",
        },
        {
          label: "User",
          field: "User",
        },
        {
          label: "Login Date",
          field: "Date",
        },
        {
          label: "Logout Date",
          field: "EndTime",
        },
        {
          label: "Login/Logout",
          field: "EndMethod",
        },
        {
          label: "Platform",
          field: "Location",
         },

      ],
      pageOptions: pageOptions,
      form: {
        limit: 10,
         user: "",
      },
      form2: {
        orderNumber: "",
        text: "",
        Date: "",
      },
      purchaseSupplier : null
    }
  },
  methods: {
    async getStoreDB() {
      try {
        this.$utils.showLoading();
        let id = this.$route.params.id; // Use params instead of query
        console.log(this.id, "this is the info");

        const response = await axios.get(subscriptionTomcatApi + `customer/getStoreByID/${this.id}`);

        this.$utils.hideLoading();
        this.datab = response.data;
        } catch (error) {
        this.$utils.hideLoading();

        return errorHandler.tomcatError(error);
      }
    },
    searchPurchaseOrders() {
      this.$utils.showLoading();
      return this.$store.dispatch(`getLoginStats`, this.form)
          .then(res => {
            this.purchaseOrder = res;
          })
          .finally(() => this.$utils.hideLoading());
    },

  }

}
</script>

<style scoped>
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>