<template>
  <div class="page">
    <div class="cover-header">
      <img alt="" class="img-fluid" src="../assets/logo.png"/>
    </div>
    <h3 class="text-white text-center">Lyt-Soft BackOffice</h3>
    <form @submit.prevent="login" class="login-box">

      <div class="col-12 text-center text-secondary">
        <h6>FRANCHISE LOGIN</h6>
      </div>
      <div class="form-group mb-2" v-if="!disableLogin">
        <label>
          <font-awesome-icon icon="fa-solid fa-user" />
          Username
        </label>
        <input class="form-control" placeholder="Username" v-model="form.email" type="text" autocomplete="email" required/>
      </div>

      <div class="form-group mb-2" v-if="!disableLogin">
        <label>
          <font-awesome-icon icon="fa-solid fa-lock" />
          Password
        </label>
        <div class="password-input">
          <input class="form-control" placeholder="Password" v-model="form.password" :type="passwordFieldType" autocomplete="current-password" required/>
          <input type="checkbox" v-model="showPassword"> Show Password
        </div>
        <div class="mb-3 form-group text-center">
          <a href="#" @click="openResetPasswordAlert">Reset Password</a>
        </div>
      </div>

      <!-- Link text for resetting password -->
      <div class="mb-3 form-group text-center" v-else>
        <label>
          Select Store
          <select v-model="currentStore" style="width: 245px;" class="form-select">
            <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store.StoreName }}</option>
          </select>
        </label>

      </div>

      <button type="submit" v-if="!disableLogin" class="login_btn">Login</button>
      <div v-else>
      <button type="button" @click="selectStore"  class="login_btn">Select Store</button>
      <button type="button" @click="FranchiseManager"  class="login_btn mt-4" >Franchise Manager</button>
      </div>
    </form>

  </div>
</template>

<script>
import Swal from 'sweetalert2';
import {Storage} from "@/Services/Storage";
import axios from "axios";
import StoreManagement from "@/views/FranchiseManagement/StoreManagement/StoreManagements.vue";
import router from "@/router";
export default {
  name: "LoginView",
  beforeMount() {
    this.initialLogin();
  },
  computed: {
    allStores(){
      return this.$store.getters.getAllStores;
    },
    disableLogin(){
      return (this.allStores && this.allStores.length > 0);
    },
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password';
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      currentStore : null,
      showPassword: false
    }
  },
  methods: {
    async initialLogin() {
      await this.$store.commit(`setAllStores`)
      let currentStoreInState = await Storage.getCurrentStore();
      let stores = this.allStores;
      if (currentStoreInState) {
        this.currentStore = currentStoreInState;
        return this.selectStore();
      }else if (stores.length === 1){
        this.currentStore = stores[0];
        return this.selectStore();
      }
    },
    FranchiseManager(){
    this.$router.push({name: 'StoreManagements'});
    },
    login() {
      this.$utils.showLoading();
      return this.$store.dispatch(`mobileUserLogin`,this.form)
          .finally(()=>this.$utils.hideLoading())
    },
    selectStore(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      console.log(this.currentStore)
      return this.$store.dispatch(`loginToStore`,this.currentStore)
          .finally(()=>this.$utils.hideLoading())
    },
    openResetPasswordAlert() {
      // Use SweetAlert to prompt for email input
      return Swal.fire({
        title: "Reset Password",
        text: "Please enter your email address:",
        input: "email",
        inputPlaceholder: "Enter your email",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        inputValidator: (value) => {
          if (!value) {
            return "You need to enter an email address!";
          }
        },
      }).then(async (result) => {
        // Handle the result, e.g., send reset password link
        if (result.value) {
          try {
            // Send a POST request to the reset password API endpoint
            const response = await axios.post('https://chrilantech.com/LytSoftPosOnline/api/email-reset-password', {
              email: result.value
            });

            // Check the response status
            if (response.status === 200) {
              // Password reset email sent successfully
              return Swal.fire({
                title: "Email sent!",
                text: "We've sent a reset password link to your email.",
                icon: "success",
              });
            } else if (response.status === 403) {
              // Email address is not registered or incorrect
              return Swal.fire({
                title: "Wrong Email",
                text: "Please use the email which company was registered with.",
                icon: "error",
              });
            } else {
              // Request failed or returned an unexpected status code
              return Swal.fire({
                title: "Error",
                text: "Something went wrong. Please try again later.",
                icon: "error",
              });
            }
          } catch (error) {
            console.error("Error sending reset password email:", error);
            // Handle error if request fails
            return Swal.fire({
              title: "Error",
              text: "Something went wrong. Please try again later.",
              icon: "error",
            });
          }
        }
      });
    },},}


</script>

<style scoped lang="scss">
body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}

.page {
  height: 100vh;
}

.img-fluid {
  height: 250px;
  width: 260px;
  transition: 2s all ease-in-out;
  transform-origin: center;
}

.img-fluid:hover {
  transform: rotate(720deg);
}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  border-radius: 0 0 15em 15em;
}

@media only screen and (max-height: 750px) {
  .cover-header {
    height: 46%;
  }
}

.login-box {
  position: absolute;
  bottom: 23%;
  right: 50%;
  transform: translate(50%, 38%);
  width: 90vw;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 1.5em;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);
}

.form-group {
  position: relative;
}

.password-input {
  position: relative;
}

.icon {
  position: absolute;
  left: 10px;
  border-right: 1px solid rgba(222, 222, 222, 0.5);
  padding-right: 5px;
  top: 37px;
  color: rgba(200, 200, 200, 0.9);
  font-size: 1.6em;
}

.form-control {
  border: 1px solid #ccc;
  background: rgba(0, 0, 0, 0);
  display: block;
  margin: 5px auto;
  text-align: left;
  padding: 0 40px 0 50px;
  height: 40px;
  border-radius: 1em;
  outline: none;
  color: #a0a0a0;
  transition: ease-in-out 200ms;
}

.form-control:focus {
  background-color: transparent;
  font-weight: 400;
  outline: none;
}

select {
  width: 100%;
}

.login_btn {
  width: 100%;
  height: 40px;
  font-size: 0.8em;
  color: white;
  padding-left: 20px;
  text-transform: uppercase;
  font-weight: 300;
  background: linear-gradient(205deg, #0B2550 0%, #295687 100%);
  border-radius: 1em;
  outline: none;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

label {
  font-style: italic;
  font-weight: 500;
  font-size: 1em;
  color: #6c757d !important;
  margin-bottom: 0;
  margin-left: 20px;
}
</style>
