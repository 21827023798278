<template>
  <div>
    <div class="page">

      <!--       modal to buy a new lisence for a store username-->
      <div class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Enter Password</h1>
              <button id="close3" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="userLogin" class="login-box">
                <div class="col-12 text-center text-secondary">
                </div>
                <div class="form-group mb-2">
                  <label >
                    <font-awesome-icon icon="fa-solid fa-lock" />
                    Password
                    <input
                        class="form-control"
                        placeholder="Password"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        autocomplete="current-password"
                        required
                    />
                  </label>
                </div>

                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" id="showPassword" v-model="showPassword" />
                  <label class="form-check-label" for="showPassword">Show Password</label>
                </div>
                <button type="submit" class="login_btn">Login</button>
              </form>

            </div>
          </div>

        </div>
      </div>
      <!--      end-->

      <!--       modal to buy a new lisence for a store-->
      <div class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Select Store</h1>
              <button id="close3" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3 form-group text-center" >
                <label>
                  Select Store to buy License for:
                  <select v-model="currentStore" style="width: 245px;" class="form-select">
                    <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store.StoreName }}</option>
                  </select>
                </label>
              </div>

              <button type="submit" v-if="!disableLogin" class="login_btn">Login</button>
              <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal5" @click="selectStore3" v-else class="login_btn" >Select Store</button>              </div>
          </div>

        </div>
      </div>
      <!--      end-->

      <!--       modal to open the buy a license-->
      <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Buy License</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div v-if="quoteAmount !== null" class="alert alert-info">
                Amount to Pay: {{ quoteAmount }}
              </div>
              <form @submit.prevent="getQuote">
                <div class="mb-3">
                  <label for="numberOfDevices" class="form-label">Number of Devices</label>
                  <input type="number" v-model="numberOfDevices" class="form-control" id="numberOfDevices" required />
                </div>
                <div class="mb-3 form-check">
                  <input type="checkbox" v-model="isAnnual" class="form-check-input" id="isAnnual" />
                  <label class="form-check-label" for="isAnnual">Is Annual</label>
                </div>
                <button type="submit"  class="btn btn-primary w-100" v-if="!quoteAmount">Submit</button>
                <button type="button" class="btn btn-success w-100" v-else data-bs-toggle="modal" data-bs-target="#exampleModal4" @click="getMainID">Confirm</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--       modal ends here-->


      <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Select Store</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3 form-group text-center" >
                <label>
                  Select Store
                  <select v-model="currentStore" style="width: 245px;" class="form-select">
                    <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store?.StoreName }}</option>
                  </select>
                </label>
              </div>

              <button type="submit" v-if="!disableLogin" class="login_btn">Login</button>
              <button type="button" @click="selectStore2" v-else class="login_btn">Select Store</button>              </div>
          </div>

        </div>
      </div>
      <!--end-->
      <div class="cover-header">
        <h3 class="text-white text-center">Lyt-Soft Franchise Manager</h3>
      </div>
      <!-- Button trigger modal -->
      <button type="button" id="modalopen" style="display: none"  data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-primary" >
        Launch demo modal
      </button>



      <div class="modal fade" id="Createstore" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
           aria-labelledby="CreatestoreLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="CreatestoreLabel">
                <!-- Dynamically set the modal title based on step -->
                <template v-if="currentStep === 1">Select Store</template>
                <template v-if="currentStep === 2">Enter Password</template>
                <template v-if="currentStep === 3">Enter Storeroom Details</template>
              </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div v-if="currentStep === 1">
                <div class="mb-3 form-group text-center" >
                  <label>
                    Select Store
                    <select v-model="currentStore" style="width: 245px;" class="form-select">
                      <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store?.StoreName }}</option>
                    </select>
                  </label>
                </div>
                <button class="btn btn-primary mt-3 w-100" :disabled="!currentStore" @click="goToNextStep('select-store')">
                  Next
                </button>
              </div>
              <div v-if="currentStep === 2">
                <form @submit.prevent="newStoreUserLogin" class="login-box ">
                  <div class="mb-2 ">
                    <label class="d-flex flex-column justify-content-start align-items-start gap-2">
                      <label class="password-label">
                        <font-awesome-icon icon="fa-solid fa-lock" />
                        Password
                      </label>

                      <input
                          class="form-control"
                          placeholder="Password"
                          v-model="password"
                          :type="showPassword ? 'text' : 'password'"
                          autocomplete="current-password"
                          required
                      />
                    </label>
                  </div>

                  <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" id="showPassword" v-model="showPassword" />
                    <label class="form-check-label" for="showPassword">Show Password</label>
                  </div>
                  <button type="submit" class="login_btn">Login</button>
                </form>
              </div>


              <form v-if="currentStep === 3" @submit.prevent="Createstoreroom">
                <div class="mb-2">
                  <label for="store">Enter store name</label>
                  <input class="form-control" id="store" v-model="forms.storeName" required>
                </div>
                <div class="mb-2">
                  <label for="store">Enter store reference name</label>
                  <input class="form-control" id="store" v-model="forms.storeReference" required>
                </div>
                <div class="mb-2">
                  <label for="store">Enter store EmailAddress</label>
                  <input class="form-control" id="store" v-model="forms.email" required>
                </div>
                <button :disabled="isSubmitting" type="submit" class="lyt-soft-btn h-50 w-100">Submit</button>
              </form>
            </div>
            <div class="modal-footer">
              <button
                  type="button"
                  id="CreatestoreClose"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ cardTitle }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <div class="d-flex flex-column gap-2">
                <button v-if="cardTitle === 'Store-Management'" @click="LoginPush" class="btn btn-primary w-100">Login to store</button>
                <button v-if="cardTitle === 'Store-Management'" data-bs-toggle="modal" data-bs-target="#Createstore" class="btn btn-primary w-100">Create a Store/StoreRoom</button>
                <button v-if="cardTitle === 'Store-Management'" data-bs-toggle="modal" data-bs-target="#exampleModal2" class="btn btn-primary w-100">Update Store Details</button>
                <button v-if="cardTitle === 'Store-Management'" data-bs-toggle="modal" data-bs-target="#exampleModal3" class="btn btn-primary w-100">Buys License</button>
                <button v-if="cardTitle === 'Reports-Platform'" @click="OpenHome" class="btn btn-primary w-100">Dashboard</button>
                 <button v-if="cardTitle === 'Reports-Platform'" @click="OpenCashup" class="btn btn-primary w-100">CashUp</button>
                <button v-if="cardTitle === 'Reports-Platform'" @click="OpenVAT" class="btn btn-primary w-100">VAT</button>
                 <button v-if="cardTitle === 'Reports-Platform' && (permissionsStringArray?.includes('SALES REPORTS'))" @click="OpenSales" class="btn btn-primary w-100">Sales</button>
                <button v-if="cardTitle === 'Reports-Platform' && (permissionsStringArray?.includes('FINANCE REPORTS'))" @click="OpenFinance" class="btn btn-primary w-100">Finance</button>
                <button v-if="cardTitle === 'Reports-Platform' && (permissionsStringArray?.includes('STAFF REPORT'))" @click="OpenSupervisor" class="btn btn-primary w-100">Supervisor</button>
                <button v-if="cardTitle === 'Franchise-HR' && permissionsStringArray?.includes('CREATE FRANCHISE USER')" @click="CreateEmp" class="btn btn-primary w-100">Create Franchise Employee</button>
                <button v-if="cardTitle === 'Franchise-HR' && permissionsStringArray?.includes('UPDATE FRANCHISE USER')" @click="EditEmp" class="btn btn-primary w-100">Edit Franchise Employee</button>
                <button v-if="cardTitle === 'Franchise-HR' && permissionsStringArray?.includes('ARCHIVE FRANCHISE USER')" @click="EmpSecurity" class="btn btn-primary w-100">Franchise Employee Security</button>
                <div v-if="cardTitle === 'Support'">
                  <div class="mb-3 form-group text-center" >
                    <label>
                      Select Store
                      <select v-model="currentStore" style="width: 245px;" class="form-select">
                        <option class="w-100" v-for="store in allStores" :value="store" :key="store.id">{{ store?.StoreName }}</option>
                      </select>
                    </label>
                  </div>

                  <button type="submit" v-if="!disableLogin" class="login_btn">Login</button>
                  <button type="button" @click="selectStore" v-else class="login_btn">Select Store</button>              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="card-container">
          <div
              class="card"
              v-for="(card, index) in visibleCards"
              :key="index"
              @click="handleCardClick(card.title)"
          >
            <h4 class="card-title">{{ card.title }}</h4>
            <p class="card-content">Manage {{ card.title }} operations here.</p>
            <!--          <p class="card-content" v-if={card.title === "Exit" >Go back to Franchise Login</p>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Storage} from "@/Services/Storage";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn.vue";
import Swal from "sweetalert2";
import {Modal} from "bootstrap";
import axios from "axios";
import NetworkStatus from "@/components/layout/NetworkStatus.vue";
import NavigationBar from "@/components/layout/navigation/NavigationBar.vue";
import StoreManagerNavigationDrawer from "@/views/StoreManagerNavigationDrawer.vue";

export default {
  name: "StoreManagement",
  components: {NavigationBar, StoreManagerNavigationDrawer, NetworkStatus, PageWithBackBtn},
  beforeMount() {
    this.$utils.showLoading()
    // this.LoadPermissions();
    this.loadloggedinuser();
    this.getMainID();
    // this.loadloggedinuser();
    this.getStoreDetails();
  },
  computed: {
    visibleCards() {
      return this.cardTitles.filter((card) => {

        if(this.permissionsStringArray?.length > 0) {
          if (card.requiredPermission === '' || card.title === "Store-Management") {
            return true
          }
          return this.permissionsStringArray.includes(card.requiredPermission);
        }
      });

    },
    allStores() {
      return this.$store.getters.getAllStores;
    },
    disableLogin(){
      return (this.allStores && this.allStores.length > 0);
    },
  },
  data() {
    return {
      password: "",
      showPassword: false, // Add a data property for checkbox state
      numberOfDevices: 0,
      userlog: "",
      newbtoa: "",
      currentStep:1,
      isAnnual: false,
      MainId: "",
      quoteAmount: null,
      localid: generateUUID(),
      isSubmitting: false,
      loggedin: { authorities: [] }, // Store user authorities
      permissionsStringArray: [],
      storeDetailsArray: [],
      roles: [],
      currentStore : null,
      forms: {
        storeName: '',
        storeReference: '',
        email: '',
      },
      form: {
        hasVat: false,
        allowBelowZero: false,
        useSeperateLine: false,
        storeName: null,
        storeAddress: null,
        gateEntry: false,
        storeAddress2: null,
        tpin: null,
        storeReference: null,
        contact: null,
        invoiceMessage: null,
        termsAndConditions: null,
        backOfficePrinter: null,
      },
      cardTitle: "",
      cardTitles: [
        { title: "Store-Management", requiredPermission: 'CREATE STORE' },
        { title: "Support", requiredPermission: '' },
        { title: "Reports-Platform", requiredPermission: 'REPORTS DASHBOARD' },
        { title: "Franchise-HR", requiredPermission: 'CREATE FRANCHISE USER' },

      ]
    };
  },
  mounted() {
    this.loadloggedinuser();
  },
  watch: {
    currentStore(newStore) {
      if (newStore) {
        this.fetchStoreDetails(newStore.StoreDB);
      }
    },
  },
  methods: {
    async goToNextStep(step) {
      if (this.currentStep < 3) {
        this.currentStep++;
      }
      if(step && step === 'select-store' && this.currentStore) {
        this.currentStep = 2;
        return this.$store.dispatch(`selectstoretoadd`,this.currentStore)
            .finally(()=>{
              this.$utils.hideLoading()})
      }
    },
    async userLogin() {
      try {
        // 1. Submit the first request to validate the user with the password
        const res = await this.$store.dispatch("validateUser2", this.password);
      } catch (error) {
        // Handle any errors that occur during login
        console.error("Error during login process:", error);
        responses.throwErr("An error occurred. Please try again.");
      } finally {
        this.makepayment()
      }
    },
    async newStoreUserLogin(){
      try {
        this.$utils.showLoading()
        await this.getQuote();
        const res = await this.$store.dispatch("validateUser2", this.password);
        if(res) {
          this.currentStep = 3;
        }else {
          responses.showInfo('Incorrect Password')
        }
      } catch (error) {
        // Handle any errors that occur during login
        console.error("Error during login process:", error);
        responses.throwErr("An error occurred. Please try again.");
      } finally {
        this.$utils.hideLoading()
      }
    },
    async sendRoute() {
      this.$utils.showLoading()
      let userlog = await Storage.getCurrentUser()
      const payload = {
        mainCustomerID: this.MainId,
        user: userlog.Name,
        numberOfDevices: parseInt(this.numberOfDevices, 10),
        total: parseInt(this.quoteAmount, 10),
        isAnnual: this.isAnnual,
        storeDB: await Storage.getCurrentStoreDB()
      };
      this.newbtoa = btoa(JSON.stringify(payload))
      console.log(this.newbtoa)
    },
    makepayment() {
      const data = {
        numberOfDevices: this.numberOfDevices,
        total: parseInt(this.quoteAmount, 10),
        isAnnual: this.isAnnual,
      };

      localStorage.setItem('licenseData', JSON.stringify(data));
      console.log('Data saved to local storage:', data);
      const payload = {
        API_KEY: "42cd52f4-5081-11ee-92a6-00163e8d4996",
        amount: 1,
        Service_Description: "Purchase of extra license",
        Client_Refrence: this.newbtoa,
        Cancel_Url: "http://192.168.1.165:8080/UnsuccesfulPurchase",
        Sucess_Url: "http://192.168.1.165:8080/SuccessfulPurchace"
      };
      return axios.post(gateway + 'GateWay/CreateTransaction', payload)
          .then(({data}) => {
            return window.open(data.url,'_self');
          }).catch((err) => errorHandler.tomcatError(err));
    },
    async getQuote() {
      try {
        this.$utils.showLoading()
        const response = await axios.get(
            backoffice + "users/{DB}/get-quote-amount?numberOfDevices=" + this.numberOfDevices + "&isAnnual=" + this.isAnnual
        );

        console.log("Full Response:", response); // Log full response
        console.log("Response Data:", response.data); // Log response data

        // Check if amount is inside response.data or another key
        this.quoteAmount = response.data.amount || response.data;

        console.log(this.quoteAmount, "amount to be paid");
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
      finally {
        this.$utils.hideLoading();
      }
    },


    // confirmPurchase() {
    //   this.$utils.showLoading()
    //   const payload = {
    //     "API_KEY": "42cd52f4-5081-11ee-92a6-00163e8d4996",
    //     "amount": this.quoteAmount,
    //     "Service_Description": "Purchase of extra license",
    //     "Client_Refrence": "",
    //     "Cancel_Url": "http://192.168.1.166:8080/UnsuccesfulPurchase",
    //     "Sucess_Url": "http://192.168.1.166:8080/SuccessfulPurchace"
    //   };
    //   const paymentUrl = "https://lna.abc.co.zm/KazangPaymentGateway/GateWay/CreateTransaction";
    //   const queryString = Object.keys(payload).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`).join('&');
    //   window.open(`${paymentUrl}?${queryString}`, "_blank");
    //
    //   this.$utils.hideLoading()
    // },
    selectStore(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      return this.$store.dispatch(`loginToStoreFromFranchise`,this.currentStore)
          .finally(()=>this.$utils.hideLoading())
    },
    selectStore3(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      document.getElementById("close3").click();
      return this.$store.dispatch(`selectstoretoadd`,this.currentStore)
          .finally(()=>{
            this.$utils.hideLoading()})

    },
    selectStore2(){
      this.$utils.showLoading()
      if (!this.currentStore) {
        this.$utils.hideLoading()
        return responses.showWarning(`Please Select a Store`)
      }
      return this.$store.dispatch(`loginToStoreFrmFranchise`,this.currentStore)
          .finally(()=>this.$utils.hideLoading())
    },
    async loadloggedinuser() {
      try {
        this.$utils.showLoading();
        let mobileUser = await Storage.getMobileUser();
        const response = await axios.get(
            backoffice + `api/franchise/get-signed-in?email=${mobileUser.email}`
        );

        this.loggedin = response.data; // Store user data
        // console.log(this.loggedin, "here is the array");
        this.permissionsStringArray =  response.data?.map(item => item?.name?.toUpperCase());
      } catch (err) {
        errorHandler.tomcatError(err);
      } finally {
        this.$utils.hideLoading();
      }
    },
    async getMainID() {
      try {
        this.$utils.showLoading();
        let DB = await Storage.getAllStores();
        let Store = DB[0].StoreDB;
        const response = await axios.get(
            franchiseTomcatApi + `stores/getRegistrationDetails?database=${Store}`
        );
        this.MainId = response.data.mainCustomerID; // Store user data
        console.log(this.MainId, "here is the MAINid");
      } catch (err) {
        errorHandler.tomcatError(err);
      } finally {
        this.$utils.hideLoading();
      }
    },
    hasAuthority(authName) {

      return this.loggedin.authorities?.some((auth) => auth.name === authName);
    },
    async LoadPermissions() {
      try {
        this.$utils.showLoading();
        let DB = await Storage.getAllStores();
        let Group = DB[0].Group;
        const response = await axios.get(backoffice + `api/franchise/role-permissions/${Group}`);
        console.log(backoffice, "here is the backoffice");

        this.roles = response.data; // Store response in array
      }
      catch (err) {
        errorHandler.tomcatError(err)
      }
      finally{
        this.$utils.hideLoading()
      }
    },
    logOutOfFranchise() {
      return Swal.fire({
        title: "Are you sure?",
        text: "You are logging out of the Current Franchise",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#dd3",
        confirmButtonText: "Yes, Log Out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$utils.showLoading();
          let deviceID = await Storage.getDeviceID();
          localStorage.clear();
          await Storage.setDeviceID(deviceID);
          await this.$router.push({ name: "login" });
          return window.location.reload(true);
        }
      });
    },
    handleCardClick(cardTitle) {

      this.cardTitle = cardTitle;
      if (cardTitle === "Store-Management" || cardTitle === "Reports-Platform" || cardTitle === "Franchise-HR"|| cardTitle === "Support") {
        document.getElementById("modalopen").click();
      } else {
        this.navigateToPage(cardTitle);
      }
    },
    async OpenHome() {
      this.$utils.showLoading();
      return this.$store.dispatch('OpenHome')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenFranchise() {
      this.$utils.showLoading();
      return this.$store.dispatch('OpenFranchise')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenCashup() {
      this.$utils.showLoading();
      return this.$store.dispatch('OpenCashup')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenAccount() {
      this.$utils.showLoading();
      return this.$store.dispatch('OpenAccount')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenStock() {
      this.$utils.showLoading();
      return this.$store.dispatch('openStock')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenVAT() {
      this.$utils.showLoading();
      return this.$store.dispatch('openVat')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenSales() {
      this.$utils.showLoading();
      return this.$store.dispatch('openSales')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenFinance() {
      this.$utils.showLoading();
      return this.$store.dispatch('openFinance')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenSupervisor() {
      this.$utils.showLoading();
      return this.$store.dispatch('openSupervisor')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },


    async fetchStoreDetails(storeDb) {
      if (!this.currentStore) return;
      try {
        await this.getStoreDetails(storeDb);
      } catch (error) {
        console.error("Failed to fetch store details:", error);
      }
    },

    async getStoreDetails(selectedDb) {
      this.$utils.showLoading();
      const result = await Promise.all([
        this.$store.dispatch('getCustomerDetails', this.currentStore).then((store) => {

          if (!store) return null;

          this.storeDetailsArray.push(store);
          this.form.storeName = store.CustomerName;
          this.form.contact = store.Cell;
          this.form.storeAddress = store.Addr1;
          this.form.storeAddress2 = store.Addr2;

          // Process TPIN
          let tpin = store.Addr3.replaceAll('TPIN', '').trim();
          tpin = tpin.replaceAll(':', '').trim();
          this.form.tpin = tpin;

          if (this.currentStore) {
            this.form.storeReference = this.currentStore?.StoreName;
          }

          return store;
        }),
      ]);
      this.$utils.hideLoading();
      return result;
    },
    CreateEmp(){
      this.$router.push({name: 'Create-Employee'})
    },
    EditEmp(){
      this.$router.push({name: 'Edit-Employee'})
    },
    EmpSecurity(){
      this.$router.push({name: 'Franchise-Security'})
    },
    navigateToPage(cardTitle) {
      const routes = {
        "Franchise-Securities": "employee-security",
        "Franchise-Employees": "employee-search",
      };

      if (routes[cardTitle]) {
        this.$router.push({ name: routes[cardTitle] });
      } else {
        console.warn("No route defined for:", cardTitle);
      }
    },
    async Createstoreroom() {
      let forms = this.forms;
      this.storeDB = await Storage.getCurrentStoreDB();

      // Ensure that storeDetailsArray is not empty before accessing it
      if (this.storeDetailsArray.length > 0) {
        const storeDetails = this.storeDetailsArray[0];

        if(this.localid) {
          this.mainCustomerID = storeDetails.mainCustomerID
          // Extract digits from the TPIN field
          let tpinValue = storeDetails.Addr3?.replace(/^\D+/, "").trim(); // Remove non-digit prefix and trim spaces
          tpinValue = tpinValue.toLowerCase().replaceAll("tpin","");
          tpinValue = tpinValue.toLowerCase().replaceAll(":","");

          // Include store details from storeDetailsArray in the payload
          let obj = {
            ...forms,
            localid: this.localid,
            tpin: tpinValue, // Use the cleaned-up TPIN
            address: storeDetails.Addr1,
            Cell: storeDetails.Cell,
            address2: storeDetails.Addr2,
            annual: false,
            mainCustomer: storeDetails.mainCustomerID,
            deviceCount: 0,
            storeLocation: null,
            subscriptionType: 3,
            queryParams: {
              mainCustomerID: storeDetails.mainCustomerID,
            },
          };

          this.$utils.showLoading();
          const response =  await this.$store
              .dispatch("addStoreToCustomer", obj)
              .finally(() => this.$utils.hideLoading());
          if(response?.id) {
            responses.showSuccess('Store Created Successfully');
            // clear the form fields. and generate a new local id if the request is successful
            this.forms = {};
            // close the creat new store modal upon creation the store
            const modalElement = document.getElementById('Createstore');
            const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
            modalInstance.hide();
           this.localid = null;
          }
        }




      } else {
        console.error("Store details array is empty");
        this.isSubmitting = false;

      }
    },
    LoginPush() {
      this.$router.push({name: 'login'})
    },

  }
};
</script>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 20%;
  border-radius: 0 0 15em 15em;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  width: 100%;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

/* Hover Effect */
.card:hover {
  background-color: #343a40; /* Dark background on hover */
  color: white; /* Change text color to white */
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Click Effect */
.card:active {
  transform: translateY(0); /* Slight press effect */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.icon {
  position: absolute;
  left: 10px;
  border-right: 1px solid rgba(222, 222, 222, 0.5);
  padding-right: 5px;
  top: 37px;
  color: rgba(200, 200, 200, 0.9);
  font-size: 1.6em;
}
.login_btn {
  width: 100%;
  height: 40px;
  font-size: 0.8em;
  color: white;
  padding-left: 20px;
  text-transform: uppercase;
  font-weight: 300;
  background: linear-gradient(205deg, #0B2550 0%, #295687 100%);
  border-radius: 1em;
  outline: none;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.root-pane {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.navigation-drawer {
  min-width: 285px;
  width: 100%;
  height: 100vh;
}

.navigation-route-view {
  transition: 0.5s ease;
}

.offline-banner {
  position: sticky;
  top: 0;
  left: 0;
  padding: 5px;
  display: block;
  text-align: center;
  width: 100%;
  background: rgb(220, 53, 69);
  color: white;
  right: 0;
}
</style>
