<template>
  <div class="sidebar">
    <h3>Admin Messages</h3>
    <div v-for="(msg, index) in messages" :key="index" class="message-card">
      <p><strong>Message:</strong> {{ msg.message }}</p>
      <p><strong>Created At:</strong> {{ msg.createdAt }}</p>
      <p><strong>URL:</strong> <a :href="msg.url" target="_blank">{{ msg.url }}</a></p>

      <!-- Show download button if the image is present -->
      <button v-if="msg.image" @click="downloadImage(msg.image, `image_${msg.id}.png`)">
        Download Image
      </button>
    </div>
  </div>
</template>



<script>
import {Storage} from "@/Services/Storage";
import axios from "axios";
import Swal from "sweetalert2";

export default {

  data() {
    return {
      messages: ["System Update at 10 PM", "New Feature Released!", "Check your inbox for details"],
      message: "",
      response1:[]
    };
  },
  async beforeMount() {
    await this.checkformessages();

  },
  methods: {
    async downloadImage(base64String, filename) {
      try {
        // Convert base64 to a Blob
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" });

        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL after download
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    },
    openMessageAlert() {
      Swal.fire({
        title: "New Message",
        text: this.message,
        icon: "info",
        confirmButtonText: "Noted",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios.post(franchiseApi+`messages/mark-as-read/98998`, {
            messageReceived: true, // Customize payload as needed
          })
              .then(response => {
                console.log("Message acknowledged:", response.data);
              })
              .catch(error => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              })
              .finally(() =>
                  this.checkformessages()
              )
        }
      });
    },
    async checkformessages() {
      try {
        this.$utils.showLoading();

        let DB = await Storage.getAllStores();
        let Group = DB[0]?.Group;
        let StoreDB = await Storage.getCurrentStoreDB();
        let currentuser = await Storage.getCurrentUser()
        let username = currentuser.Username
        console.log(currentuser, currentuser)
        const response = await axios.get(
            `${franchiseTomcatApi}messages?read=false&database=${StoreDB}&username=${username}&group=${Group}`
        );

        console.log("API Response:", response.data); // Log response

        this.messages = response.data.map(msg => ({
          message: msg.message,
          createdAt: msg.createdAt,
          url: msg.url,
          image: msg.image || null, // If there's no image, set it as null
          id: msg.id
        }));
      } catch (err) {
        console.error("Error fetching messages:", err);
      } finally {
        this.$utils.hideLoading();
      }
    }
  },
};
</script>

<style scoped>
.sidebar {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f4f6f9; /* Light background for contrast */
}

.message-card {
  border: 2px solid dodgerblue;  /* Blue border for emphasis */
  padding: 15px;
  margin-bottom: 15px;  /* Space between messages */
  border-radius: 8px;  /* Smooth rounded corners */
  background-color: white; /* White background for readability */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

h3 {
  color: white; /* Dark gray for contrast */
  margin-bottom: 15px;
}

p {
  color: #555; /* Slightly darker text for readability */
  margin: 5px 0;
}

a {
  color: dodgerblue; /* Links stand out */
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

button {
  background-color: dodgerblue;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

button:hover {
  background-color: #1e90ff;
}
</style>

