import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
    name: 'specials',
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        searchSpecials:async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi +`specials/${storeDB}/search`,{
                params : payload
            }).then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },


        getSpecialByID :async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`specials/${storeDB}/${id}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },
        deleteSpecial :async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi+`specials/${storeDB}/${id}`)
                .catch(err=>errorHandler.tomcatError(err))
        },
        getItemsBySpecial :async (context , id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi+`specials/${storeDB}/getItemsBySpecial/${id}`)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        },
        createSpecial: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            payload.User = user;
            payload.PC = "Online";
            return axios.post(franchiseTomcatApi+`specials/${storeDB}`,payload)
                .then(({data}) => data).catch(err=>errorHandler.tomcatError(err));
        },
        updateSpecial: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.put(franchiseTomcatApi+`specials/${storeDB}/${payload.id}`,payload)
                .catch(err=>errorHandler.tomcatError(err));
        },

        addItemToSpecial: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi+`specials/${storeDB}/addSpecialDetails/${payload.specialID}`,payload)
                .catch(err=>errorHandler.tomcatError(err));
        },

        removeItemsFromSpecial: async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(franchiseTomcatApi+`specials/${storeDB}/removeItemsFromSpecial/${payload.specialID}?PLU=${payload.PLU}`)
                .catch(err=>errorHandler.tomcatError(err));
        },
    }
}
