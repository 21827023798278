import axios from "axios";
import {Storage} from "@/Services/Storage";
import queryStringify from "qs-stringify";
export default {
    name: 'Safes',
    state: {
        currentSafeType : "CASH",
        safes : [],
    },
    getters: {
        getSafes: (state) => state.safes,
        getCurrentSafeType: (state) => state.currentSafeType,
    },
    mutations: {
        setSafes:(state , payload) => state.safes = payload,
        setSafeType:(state , payload) => state.currentSafeType = payload,
    },
    actions: {
        getAllSafes: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${storeDB}/getAllPaymentSafes`)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },

        searchAssetCategories: async (context , obj) => {
            let storeDB = await Storage.getCurrentStoreDB();
            obj = `?${queryStringify(obj)}`
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${storeDB}/getAccountsByType/asset?archived=false`)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        searchSafes: async (context , obj) => {
            let storeDB = await Storage.getCurrentStoreDB();
            obj = `?${queryStringify(obj)}`
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${storeDB}/searchSafes`+obj)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        getSafesByTpe: async (context, { type, archive }) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!type) type = context.getters.getCurrentSafeType;
            else context.commit(`setSafeType`, type);

            // Include the `archived` parameter in the API request
            return axios.get(backOfficeTomcatApi + `chartOfAccounts/${storeDB}/getSafeByType`, {
                params: {
                    type: type,
                    archive: archive
                }
            })
                .then(({ data }) => context.commit(`setSafes`, data))
                .catch(err => errorHandler.tomcatError(err));
        },
        moveMoneyBetweenFranchise: async (context , payload) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(backOfficeTomcatApi+`chartOfAccounts/${storeDB}/move-money-to-branch?DB=${storeDB}`,payload)
                .then(()=>window.location.reload(true))
                .catch(err=>errorHandler.tomcatError(err));
        },
        moveMoneyBetweenAccounts: async (context , payload) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(backOfficeTomcatApi+`safeFunctions/${storeDB}/moveMoneyBetweenAccounts`,payload)
                .then(()=>window.location.reload(true))
                .catch(err=>errorHandler.tomcatError(err));
        },

        getSafeDetails : async (context , chartID) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi+`safeFunctions/${storeDB}/getSafeDetails`,{
                params : { chartID : chartID }
            }).then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },

        getSafeTransactions: async (context , safeData) =>{
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.closed = safeData.closed;
            payload.debit = safeData.debit;
            payload.chartID = safeData.chartID;
            return axios.get(backOfficeTomcatApi+`safeFunctions/${storeDB}/getSafeTransactions`,{
                params : payload
            }).then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },

        closeSafe : async (context , payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            return axios.post(backOfficeTomcatApi+`safeFunctions/${storeDB}/closeSafe`,payload)
                .catch(err=>errorHandler.tomcatError(err));
        },

        safeCloseReport: async (context,safeName) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload = {
                ...payload,
                safeName
            }
            payload = `?${queryStringify(payload)}`;
            return openUrl(franchiseApi+`SafeClose/exportReport/${storeDB}`+payload,)
        },
        getSafeCloseTotal: async (context,safeName) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseApi+`SafeClose/getTotal/${storeDB}?safeName=${safeName}`)
                .then(({data})=>data).catch(err=>errorHandler.tomcatError(err));
        },

    }
}
