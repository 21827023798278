<template>
  <div class="row justify-content-center">
    <div class="row align-content-center justify-content-center">
      <button type="button" class="btn btn-primary w-50" data-bs-toggle="modal"
              data-bs-target="#staticMoveMoneyBackdrop">
        <font-awesome-icon icon="fa-solid fa-exchange"/>
        Move Money Between Accounts
      </button>
      <div class="modal fade" id="staticMoveMoneyBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
           tabindex="-1"
           aria-labelledby="staticMoveMoneyBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title" id="staticMoveMoneyBackdropLabel">Move Money</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeMoneyMovementModal"
                      aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="processMovement">
                <div class="row mb-3">
                  <div class="col-md-6">
                    <label for="fromAccount">Sending Account</label>
                    <v-select id="fromAccount" v-model="transferForm.from" :options="fromList"
                              :required="!transferForm.from"
                              @search="searchFromSafe">
                      <template slot="no-options">
                        <b>Type to search ...</b>
                      </template>
                    </v-select>
                  </div>

                  <div class="col-md-6">
                    <label for="toAccount">Receiving Account</label>
                    <v-select id="toAccount" v-model="transferForm.to" :required="!transferForm.to" :options="toList"
                              @search="searchToSafe">
                      <template slot="no-options">
                        <b>Type to search ...</b>
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-12">
                    <div class="form-floating mt-2">
                      <input type="number" step="0.01" class="form-control" required
                             v-model="transferForm.amount" id="amount" placeholder="Amount">
                      <label for="amount">Amount</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-check form-check-inline mt-2">
                    <input class="form-check-input" type="checkbox" id="vat-checkbox" v-model="showVAT" />
                    <label class="form-check-label" for="vat-checkbox">Transer fee</label>
                  </div>
                </div>

                <div v-if="showVAT" class="row mb-3">
                  <div class="col-md-12">
                    <div class="form-floating mt-2">
                      <input type="number" step="0.01" class="form-control" required
                             v-model.number="transferForm.transactionFee" id="vat-amount" placeholder="Transfer Fee Amount">
                      <label for="vat-amount">Transfer Fee Amount</label>
                    </div>
                  </div>
                </div>

                <div class="row m-1">
                  <label for="moveReason">Movement Reason</label>
                  <textarea class="form-control" placeholder="Movement Reason" rows="3"
                            required id="moveReason" v-model="transferForm.reason"/>
                </div>

                <div class="row mt-2 d-flex justify-content-center">
                  <button class="btn btn-primary w-50 h-100" type="submit">Process</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoveMoney",
  data() {
    return {
      fromList: [],
      toList: [],
      showVAT: false, // Flag to control visibility of VAT input field

      transferForm: {
        from: null,
        to: null,
        date: this.$store.getters.currentTime,
        amount: null,
        reason: '',
        transactionFee: 0,
      }
    }
  },

  methods: {
    processMovement() {
      this.transferForm.transactionFee = parseFloat(this.transferForm.transactionFee) || 0;
      this.$utils.showLoading();
      if (this.transferForm.date.toString().includes('T')) {
        this.transferForm.date = this.transferForm.date.toString().replaceAll('T', ' ') + ':00';
      }
      return this.$store.dispatch(`moveMoneyBetweenAccounts`, this.transferForm)
          .then(() => {
            document.getElementById("closeMoneyMovementModal").click();
            return Promise.all([
              responses.showSuccess(`Movement Successful`),
              this.resetTransferForm()
            ]);
          })
          .finally(() => this.$utils.hideLoading());
    },
    resetTransferForm() {
      this.fromList = [];
      this.toList = [];
      this.transferForm = {
        from: null,
        to: null,
        date: this.$store.getters.currentTime,
        amount: null,
        reason: '',
      }
    },
    searchFromSafe(search, loading) {
      if (search) {
        loading(true);
        let obj = {
          safeName : search,
          currency : null,
        }
        return this.$store.dispatch(`searchSafes`, obj)
            .then(res => this.fromList = res)
            .finally(() => loading(false));
      }
    },

    searchToSafe(search, loading) {
      if (search) {
        loading(true)
        let obj = {
          safeName : search,
          currency : null,
        }
        return this.$store.dispatch(`searchSafes`, obj)
            .then(res => this.toList = res)
            .finally(() => loading(false));
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>