export const errorHandler = {
    throwNotFound() {
        return "Failed to Connect to Server (URL Not Found)!";
    },

    throwTimeOut() {
        return "Connection Timed Out!";
    },

    escapeHtml(str) {
        if (!str) return str;
        return str
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
    },

    extractErrorMessage(response) {
        if (!response || typeof response !== "object") return response;

        let errorMessage =
            response.error ||
            response.Error ||
            response.response_message ||
            response.message ||
            response.Message ||
            (response.Print?.toString().includes("failed") ? response.Print : JSON.stringify(response));

        if (response.response && response.response.Message) {
            errorMessage = response.response.Message;
        }

        return this.escapeHtml(errorMessage);
    },

    franchiseLoginError(err) {
        let errorMessage = err;

        if (err.response) {
            console.error(err.response);
            errorMessage = err.response.status === 404 ? this.throwNotFound() : this.extractErrorMessage(err.response.data);
        } else if (err.code === "ECONNABORTED") {
            errorMessage = this.throwTimeOut();
        } else {
            console.error(err);
            errorMessage = err.message;
        }

        if (errorMessage === "Network Error") {
            errorMessage = "Please check your internet connection.";
        }

        return errorMessage;
    },

    tomcatError(err, isSweetalert = false, title = "An Error Occurred") {
        let errorMessage = err;

        if (err.response) {
            console.error(err.response);

            if (err.response.status === 404) {
                errorMessage = this.throwNotFound();
            } else if (err.response.status === 500) {
                let serverError = this.extractErrorMessage(err.response.data);
                return Swal.fire({
                    title,
                    icon: "error",
                    html: `<p>Please contact support: +260958168829</p><span>${serverError}</span>`,
                    showCloseButton: true,
                });
            } else {
                errorMessage = this.extractErrorMessage(err.response.data);
            }
        } else if (err.code === "ECONNABORTED") {
            errorMessage = this.throwTimeOut();
        } else {
            console.error(err);
            errorMessage = err.message;
        }

        if (errorMessage === "Network Error") {
            errorMessage = "Please check your internet connection.";
        }

        if (!isSweetalert) {
            responses.throwErr(errorMessage, title);
        }

        throw new Error(errorMessage);
    },

    customerKazangError(err, isSweetalert = false) {
        console.log(err);
        let errorMessage = err;

        if (err.response) {
            console.error(err.response);
            errorMessage = err.response.status === 404 ? this.throwNotFound() : this.extractErrorMessage(err.response.data);
        } else if (err.Error) {
            errorMessage = err.Message;
        } else if (err.code === "ECONNABORTED") {
            errorMessage = this.throwTimeOut();
        }

        if (!isSweetalert) {
            responses.throwErr(errorMessage);
        }

        throw new Error(errorMessage);
    },
};