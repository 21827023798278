<template>
  <page-with-back-btn title="Messages">
    <RangeSelector :submit-method="searchPurchaseOrders2" />
    <div class="m-3">
      <button type="button" style="display: none" class="btn btn-primary">
        Launch demo modal
      </button>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Create new message</h1>
              <button type="button" @click="resetForm" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col">
                <label for="float-user">Users</label>
                <div class="  align-items-center">
                  <v-select
                      id="float-user"
                      v-model="selectedUser"
                      :options="$store.getters.getAllUsers"
                      :reduce="item => item.Username"
                      label="Username"
                  ></v-select>
                  <button @click="addUser" class="btn btn-primary mt-2">+</button>
                </div>

                <!-- Display Selected Users -->
                <div v-if="form2send.usernames.length" class="mt-2">
                  <div v-for="(user, index) in form2send.usernames" :key="index" class="d-flex align-items-center">
                    <span class="me-2">{{ user }}</span>
                    <button @click="removeUser(index)" class="btn btn-danger btn-sm">x</button>
                  </div>
                </div>
              </div>
                  <div class="col">
                  <label>
                    Select Stores to send messages to:
                    <div class="  align-items-center">

                    <select v-model="selectedDBS" class="form-select" required>
                      <option v-for="store in allStores" :value="store.StoreDB" :key="store.id">{{ store.StoreName }}</option>
                    </select>
                      </div>
                  </label>
                    <button @click="addStor" class="btn btn-primary mt-2">+</button>

                    <div v-if="form2send.databases.length" class="mt-2">
                      <div v-for="(user, index) in form2send.databases" :key="index" class="d-flex align-items-center">
                        <span class="me-2">{{ user }}</span>
                        <button @click="removestore(index)" class="btn btn-danger btn-sm">x</button>
                      </div>
                    </div>
                </div>
               <div class="col">
                <label for="float-platform">Platforms to send to:</label>
                <select v-model="form2send.platform" class="form-select" required>
                  <option>OnlineBackOffice</option>
                  <option>Retail Pos</option>
                  <option>Restaurant Pos</option>
                </select>
              </div>
              <div class="col">
                <label for="float-message">Message</label>
                <textarea v-model="form2send.message" class="form-control" required></textarea>
              </div>
              <div class="col">
                <label for="float-message">Url</label>
                <input v-model="form2send.url" class="form-control" ></input>
              </div>

              <div class="col mt-2">
                <label for="float-message">Attach Image</label>
                <input
                    type="file"
                    ref="addFile"
                     accept="image/png, image/jpeg"
                    @change="uploadAttachment"
                >
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary" @click="sendMessage">Send Message</button>
            </div>
          </div>
        </div>
      </div>

      <form @submit.prevent="searchPurchaseOrders2">
        <div class="row">
          <button type="button" id="hidden-modal-button" class="btn btn-primary visually-hidden" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Launch demo modal
          </button>
          <div class="col-md-4">
            <label for="float-user">User</label>
            <v-select
                id="float-user"
                v-model="form.username"
                :options="$store.getters.getAllUsers"
                :reduce="item => item.Username"
                label="Username"
            ></v-select>
          </div>
          <div class="col-md-4">
            <label for="search-limit">Limit</label>
            <input type="number" v-model="form.limit" class="form-control" placeholder="Limit" id="search-limit" />
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary w-75" type="submit">Search</button>
        </div>
      </form>
    </div>

    <div class="card m-3">
      <label class="card-header text-primary">
        <b>Messages</b>
        <div class="">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'unread' }" @click="activeTab = 'unread'">Unread Messages</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :class="{ active: activeTab === 'read' }" @click="activeTab = 'read'">Read Messages</a>
            </li>
          </ul>
        </div>

      </label>
      <div class="card-body p-0">
        <vue-good-table
            :search-options="{ enabled: true }"
            :columns="filteredColumns"
            :rows="filteredMessages"
            :pagination-options="pageOptions"
            theme="polar-bear"
            styleClass="vgt-table condensed"
        >
          <template #table-row="props">
            <!-- Read Status -->
            <span v-if="props.column.field === 'read'">
              {{ props.row.read === 0 ? "Unread" : "Read" }}
            </span>

            <!-- Image Download Button -->
            <span v-else-if="props.column.field === 'image'">
              <button v-if="props.row.image" class="btn btn-primary" @click="Download(props.row.image, `image_${props.row.id}.png`)">
                Download
              </button>
            </span>

            <!-- Action Button -->
            <span v-else-if="props.column.field === 'action'">
              <button v-if="props.row.id && activeTab === 'unread'" @click="markasread(props.row.id)">
                <font-awesome-icon class="text-danger icon" icon="trash" />
              </button>
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <FloatingAddButton data-bs-toggle="modal" data-bs-target="#exampleModal" />
  </page-with-back-btn>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage";
import RangeSelector from "@/components/layout/utils/RangeSelector";
import axios from "axios";
import {Storage} from "@/Services/Storage";
import PurchaseOrderOptions from "@/components/Stock/PurchaseOrders/PurchaseOrderOptions";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import CustomModal from "@/components/layout/utils/CustomModal";
import DeliveryNotesOptions from "@/views/Debtors/DeliveryNotes/DeliveryNotesOptions.vue";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn.vue";
import Swal from "sweetalert2";

export default {
  name: "PurchaseOrdersView",
  components: {
    PageWithBackBtn,
    DeliveryNotesOptions, CustomModal, FloatingAddButton, PurchaseOrderOptions, RangeSelector, FullPage},
  beforeMount() {
    this.searchPurchaseOrders();
    this.searchPurchaseOrders2();
    this.getStoreDB()

  },
  computed: {
    // Filter messages based on active tab
    filteredMessages() {
      return this.activeTab === "unread"
          ? this.purchaseOrder.filter(msg => msg.read === 0)
          : this.purchaseOrder.filter(msg => msg.read === 1);
    },
    // Hide action column for read messages
    filteredColumns() {
      return this.activeTab === "unread"
          ? this.columns
          : this.columns.filter(col => col.field !== "action");
    },
    allStores() {
      return this.$store.getters.getAllStores;
    },
    totalPurchaseOrderCost() {
      let total = 0;
      this.purchaseOrder.forEach(order => {
        let currencies = this.$store.getters.getStoreCurrencies;
        let poCurrency =currencies.find(cur=>cur.Symbol === order.Symbol);
        if (!poCurrency) poCurrency = this.$store.getters.getBaseCurrency;
        let convertedTotal = order.Total * poCurrency.Rate;
        total += convertedTotal;
      });
      return this.$utils.formatMoney(total);  // Format to 2 decimal places
    }
  },
  data() {
    return {
      activeTab: "unread",
      limit: 10,
      datab: [],
      suppliers: [],
      purchaseOrder: [],
      columns: [
        { label: "Platform", field: "platform" },
        { label: "Message", field: "message" },
        { label: "Store Sent From", field: "database" },
        { label: "Created At", field: "createdAt" },
        { label: "Username", field: "username" },
        { label: "Image", field: "image" },
        { label: "URLs", field: "url" },
        { label: "Read", field: "read" },
        { label: "Action", field: "action" },
      ],
      pageOptions: pageOptions,
      form: {
        limit: 10,
        currentStore:[],
        usernames: [],
      },
      selectedUser: null, // Store temporary selected user
      selectedDBS: null, // Store temporary selected user
      form2send: {
        databases:[],
        usernames: [],
        platform:[],
        message:"",
        image:"",
        url:""
      },
      form2: {
        orderNumber: "",
        text: "",
        Date: "",
      },
      purchaseSupplier : null
    }
  },
  methods: {
    markasread(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once marked as read, this message will disappear for all intended users.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return axios.put(franchiseTomcatApi + `messages/mark-as-read/${id}`)
              .then(response => {
                console.log("Message acknowledged:", response.data);
                this.searchPurchaseOrders2(); // Refresh messages after marking as read
              })
              .catch(err => {
                console.error("Error marking as read", err);
              });
        },
      });

  },
    addUser() {
      if (this.selectedUser && !this.form2send.usernames.includes(this.selectedUser)) {
        this.form2send.usernames.push(this.selectedUser);
        this.selectedUser = null; // Reset selection
      }
    },
    removeUser(index) {
      this.form2send.usernames.splice(index, 1);
    },
    addStor() {
      if (this.selectedDBS && !this.form2send.databases.includes(this.selectedDBS)) {
        this.form2send.databases.push(this.selectedDBS);
        this.selectedDBS = null; // Reset selection
      }
    },
    removestore(index) {
      this.form2send.databases.splice(index, 1);
    },
    searchPurchaseOrders2(){
      this.$utils.showLoading();
      return this.$store.dispatch(`getMeesages`, this.form)
          .then(res => {
            this.purchaseOrder = res;
          })
          .finally(() => this.$utils.hideLoading());
    },
       Download(base64String, filename) {
         try {
           // Convert base64 to a Blob
           const byteCharacters = atob(base64String);
           const byteNumbers = new Array(byteCharacters.length);
           for (let i = 0; i < byteCharacters.length; i++) {
             byteNumbers[i] = byteCharacters.charCodeAt(i);
           }
           const byteArray = new Uint8Array(byteNumbers);
           const blob = new Blob([byteArray], { type: "image/png" });

           // Create a download link
           const link = document.createElement("a");
           link.href = URL.createObjectURL(blob);
           link.download = filename;
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);

           // Revoke the object URL after download
           URL.revokeObjectURL(link.href);
         } catch (error) {
           console.error("Error downloading image:", error);
         }
      },

    async getStoreDB() {
      try {
        this.$utils.showLoading();
        let id = this.$route.params.id; // Use params instead of query
        console.log(this.id, "this is the info");

        const response = await axios.get(subscriptionTomcatApi + `customer/getStoreByID/${this.id}`);

        this.$utils.hideLoading();
        this.datab = response.data;
      } catch (error) {
        this.$utils.hideLoading();

        return errorHandler.tomcatError(error);
      }
    },
    searchPurchaseOrders() {

    },
    resetForm() {
      this.form2send = {
        usernames: [],
        currentStore: [],
        platform: [],
        message: ""
      };
    },
    async uploadAttachment(event) {
      const file = event.target.files[0];

      if (!file) return;

      // Validate file type
      const allowedTypes = ["image/png", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        alert("Only PNG and JPEG files are allowed.");
        return;
      }

      // Convert to Base64
      const base64String = await this.getBase64(file);

      // Store in form2send.image (without the 'data:image/png;base64,' prefix)
      this.form2send.image = base64String.split(",")[1];
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async sendMessage() {
      try {
        this.$utils.showLoading();

        // Ensure fields are arrays
        this.form2send.databases = Array.isArray(this.form2send.databases)
            ? this.form2send.databases
            : [this.form2send.databases];

        this.form2send.usernames = Array.isArray(this.form2send.usernames)
            ? this.form2send.usernames
            : [this.form2send.usernames];


        let DB = await Storage.getAllStores();
        let Group = DB[0].Group;

        const response = await axios.post(`${franchiseTomcatApi}messages?group=${Group}`, this.form2send);

        this.$utils.hideLoading();
        await Swal.fire("Success", "Message sent successfully!", "success");

        // Refresh messages
        await this.searchPurchaseOrders();
      } catch (error) {
        this.$utils.hideLoading();
        await Swal.fire("Error", "Failed to send message", "error");
        this.resetForm
      }

      }
    }
}

</script>

<style scoped>
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>