<template>
  <vue-drawer-layout
      ref="drawer"
      :drawer-width="300"
      :enable="false"
      @mask-click="closeDrawer">

    <div slot="drawer">
      <NavigationDrawer :close="closeDrawer" class="navigation-drawer"/>
    </div>

    <div slot="content" class="root-pane">
      <div>
        <NetworkStatus />
      </div>
      <NavigationBar :toggle="openDrawer" class="nav"/>

      <!-- Content Wrapper -->
      <div class="content-wrapper">
        <!-- Main content (100% if no messages, otherwise 80%) -->
        <div :class="{'main-content': true, 'full-width': !showMessages}">
          <router-view/>
        </div>

        <!-- Admin Messages Sidebar (20% if messages exist) -->
        <AdminSidebar v-if="showMessages" class="admin-sidebar" />
      </div>
    </div>
  </vue-drawer-layout>
</template>

<script>
import NavigationBar from "./navigation/NavigationBar.vue";
import NavigationDrawer from "./navigation/NavigationDrawer.vue";
import NetworkStatus from "@/components/layout/NetworkStatus.vue";
import AdminSidebar from "@/components/layout/messages.vue"; // Sidebar component
import axios from "axios";
import { Storage } from "@/Services/Storage";

export default {
  name: "Layout",
  components: { NavigationDrawer, NavigationBar, NetworkStatus, AdminSidebar },

  data() {
    return {
      showMessages: true, // Initially true, will be updated after API call
    };
  },

  async beforeMount() {
    await this.checkForMessages();
  },

  methods: {
    openDrawer() {
      this.$refs.drawer.toggle();
    },

    closeDrawer() {
      this.$refs.drawer.toggle(false);
    },

    async checkForMessages() {
      try {
        let DB = await Storage.getAllStores();
        let Group = DB[0]?.Group;
        let StoreDB = await Storage.getCurrentStoreDB();
        let currentuser = await Storage.getCurrentUser()
        let username = currentuser.Username
        const response = await axios.get(
            `${franchiseTomcatApi}messages?read=false&database=${StoreDB}&username=${username}&group=${Group}`
        );

        console.log("API Response navbar:", response.data);

        // If the API response is empty, hide the sidebar
        this.showMessages = response.data.length > 0;
      } catch (err) {
        console.error("Error fetching messages:", err);
        this.showMessages = false; // Default to false if the request fails
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.root-pane {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.navigation-drawer {
  min-width: 285px;
  width: 100%;
  height: 100vh;
}

.navigation-route-view {
  transition: 0.5s ease;
}

/* Layout for content and sidebar */
.content-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px); /* Adjust based on the navbar height */
}

/* Main content (80% default) */
.main-content {
  flex: 80%;
  height: 100%;
}

/* Make main content full width when sidebar is hidden */
.full-width {
  flex: 100%;
}

/* Admin Sidebar (20% width if messages exist) */
.admin-sidebar {
  flex: 20%;
  background: #004080;
  color: white;
  height: 100%;
  overflow-y: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
}
</style>
