
<template>
  <div class="drawer">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ mobileUser?.email || 'Guest User' }}</h5>
      </div>
    </div>

    <div class="accordion menu" id="accordionPanelsStayOpenExample">

      <div class="accordion-item">
        <a class="route-btn w-100 bg-transparent" @click="StoreManagement">
          <font-awesome-icon class="icon" icon="fa-solid fa-chart-simple"/>
          Store Management
        </a>
      </div>
      <a @click="Support" class="route-btn w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="gear"/>
        Support
      </a>
<!--      <a @click="Finance" class="route-btn w-100 bg-transparent">-->
<!--        <font-awesome-icon class="icon" icon="fa-solid fa-money-bills"/>-->
<!--        Franchise Money Transfers-->
<!--      </a>-->
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
              :class="{ 'accordion-button': true, 'collapsed': !Reports }"
              @click="toggleAccordion('Reports')"
              :aria-expanded="Reports ? 'true' : 'false'"

              type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="false"
              aria-controls="panelsStayOpen-collapsesix">
            Reports
          </button>
        </h2>
        <div id="panelsStayOpen-collapsesix"
             class="accordion-collapse collapse"
             :class="{ 'show': Reports }"
             aria-labelledby="panelsStayOpen-headingsix"
             v-show="Reports"
        >
          <div class="accordion-body">
            <a @click="OpenHome" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clipboard-list"/>
              Dashboard
             </a>
            <a @click="OpenCashup" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clipboard-list"/>
              CashUp
            </a>
            <a @click="OpenVAT" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clipboard-list"/>
              VAT
            </a>
            <a @click="OpenSupervisor" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clipboard-list"/>
              Supervisor
            </a>
            <a @click="OpenFinance" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clipboard-list"/>
              Finance
            </a>
            <a @click="OpenSales" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="clipboard-list"/>
              Sales
            </a>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
              :class="{ 'accordion-button': true, 'collapsed': !isSignOutOpen }"
              @click="toggleAccordion('isSignOutOpen')"
              :aria-expanded="isSignOutOpen ? 'true' : 'false'"

              type="button" data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
              aria-controls="panelsStayOpen-collapseFive">
            Franchise HR
          </button>
        </h2>
        <div id="panelsStayOpen-collapseFive"
             class="accordion-collapse collapse"
             :class="{ 'show': isSignOutOpen }"
             aria-labelledby="panelsStayOpen-headingFive"
             v-show="isSignOutOpen"
        >
          <div class="accordion-body">
            <a @click="CreateEmp" class="route-btn w-100 bg-transparent"  >
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
              Create Franchise Employee
            </a>

            <a @click="EditEmp" class="route-btn w-100 bg-transparent"  >
              <font-awesome-icon class="icon" icon="fa-solid fa-pen-to-square"/>
              Edit Franchise Employee
            </a>
            <a @click="EmpSecurity" class="route-btn w-100 bg-transparent"  >
              <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
              Franchise Securities
            </a>
          </div>
        </div>
      </div>

      <a @click="logOutOfFranchise" class="route-btn w-100 bg-transparent">
        <font-awesome-icon class="icon" icon="fa-solid fa-lock"/>
        Logout
      </a>
    </div>
  </div>

</template>


<script>
import {Storage} from "@/Services/Storage";
import CaptureExpense from "@/components/Finance/CaptureExpense.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "NavigationDrawer",
  components: {CaptureExpense},
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      mobileUser: {}, // Ensure it's an object
      isSignOutOpen: false,
      Reports: false,
      FranchiseHr: false
    };
  },

  watch: {
    isLoyaltyActivated(newValue) {
      console.log('isLoyaltyActivated changed:', newValue);
    }
  },
  async mounted() {
     await this.curUser();

  },
  computed: {

  },
  methods: {
    async curUser() {
   this.mobileUser = await Storage.getMobileUser();
    },
    async OpenHome() {
      this.$utils.showLoading();
      return this.$store.dispatch('OpenHome')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenCashup() {
      this.$utils.showLoading();
      return this.$store.dispatch('OpenCashup')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenVAT() {
      this.$utils.showLoading();
      return this.$store.dispatch('openVat')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenSales() {
      this.$utils.showLoading();
      return this.$store.dispatch('openSales')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenFinance() {
      this.$utils.showLoading();
      return this.$store.dispatch('openFinance')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async OpenSupervisor() {
      this.$utils.showLoading();
      return this.$store.dispatch('openSupervisor')
          .finally(() => {
            this.$utils.hideLoading();
            this.back();
          });
    },
    async Finance() {
      let DB = await Storage.getCurrentStoreDB();

      if (!DB) {
        Swal.fire({
          title: "First login before proceeding",
          text: "Please click the button below to Enter ones stores information before proceeding to view tickets.",
          icon: "warning",
          confirmButtonText: "Go to Store Selection",
        }).then(() => {
          this.$router.push("/FranchiseSelectStore");
        });
      }
      else {
        this.$router.push({name: 'FranchiseFinance'});

      }
    },


    Support(){
      this.$router.push({name: 'Support'});
    },
    StoreManagement(){
      this.$router.push({name: 'StoreManagements'});
    },
    Reports(){
      this.$router.push({name: 'Reports'});

    },
    FranchiseHr(){
      this.$router.push({name: 'FranchiseHR'});

    },
    toggleAccordion(section) {
      this[section] = !this[section];
    },
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },
    logOutOfFranchise() {
      return Swal.fire({
        title: "Are you sure?",
        text: "You are logging out of the Current Franchise",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#dd3",
        confirmButtonText: "Yes, Log Out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$utils.showLoading();
          let deviceID = await Storage.getDeviceID();
          localStorage.clear();
          await Storage.setDeviceID(deviceID);
          await this.$router.push({ name: "login" });
          return window.location.reload(true);
        }
      });
    },
    EmpSecurity(){
      this.$router.push({name: 'Franchise-Security'})
    },
    EditEmp(){
      this.$router.push({name: 'Edit-Employee'})
    },
    CreateEmp(){
      this.$router.push({name: 'Create-Employee'})
    },
    async reports() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Reports')
      if (!isAllowed) return;
      this.$utils.showLoading()
      return this.$store.dispatch(`openFranchisePlatform`)
          .finally(() => {
            this.$utils.hideLoading()
            this.back();
          })
    },

    //settings securities
    async sysSettings() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Settings')
      if (!isAllowed) return;
      this.$router.push({name: 'SystemSettings'})
    },


  }
}
</script>

<style lang="scss" scoped>
a {
  &:hover, &.a-exact-active {
    background: #f1f1f1;
    color: #3F8DCD;

    .icon {
      color: #3F8DCD;
    }
  }
}

.warning {
  border-radius: 50%;
  display: inline-flex; /* Ensures that the container can shrink to fit its content */
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  box-shadow: 0 0 0 9px rgba(217, 193, 98, 0); /* Starting with transparent box-shadow */
  transform: scale(1);
  animation: pulse 2s infinite;
  transform-origin: center center; /* Ensures transform scales from the center */
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 9px rgba(217, 193, 98, 1); /* Fully opaque at start */
  }
}

.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #15356E;
          font-weight: 600;
        }
      }

    }
  }
}

.menu {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}

.icon {
  margin-right: 10px;
  color: #606d77;
  font-size: 1.5rem;
}

.route-btn {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: none;
  color: #1c1c1c;
}

#footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.not-footer {
  margin-bottom: 100px;
}

.box-container {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  margin-bottom: 10px; /* Adjust as needed */
}

.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box {
  display: inline-block;
}

.text-white {
  color: white;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.accordion-collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-collapse.show {
  max-height: 10000px !important;
}

</style>

