import axios from "axios";
import {Storage} from "@/Services/Storage";
import queryStringify from "qs-stringify";

export default {
    name: 'Debtor',
    state: {},
    getters: {},
    mutations: {},
    actions: {
        createDebtor: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (storeDB === `burntofi_chrilan`){
                payload.CellNumber = payload.CellNumber.replace(`+`,``);
            }
            return axios.post(serverApi + `debtor/${storeDB}`, payload)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getAllDebtors: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `debtor/${storeDB}/search`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getDebtorBalances: async (context, IDs) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = {IDs}
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/getDebtorBalances`, obj)
                .then(({data}) => data).catch(err => errorHandler.tomcatError(err));
        },
        getDebtorByID: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(onlineSalesTomcatApi + `debtor/${storeDB}/${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getDebtorBalance: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `debtor/${storeDB}/VerifyBalance?ID=${id}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getInvoicesByDebtor: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/invoices`, payload)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },

        getCreditNotesByDebtor: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/CreditNotes`, payload)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },

        getPaymentsByDebtor: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/Payments`, payload)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },

        getProductsBoughtByDebtor: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/getProductsBought`, payload)
                .then(({data}) => {
                    data
                }).catch((err) => errorHandler.tomcatError(err))
        },

        getQuotesByDebtor: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = {
                Name: ``,
                customerID: id,
            };
            return axios.post(franchiseTomcatApi + `savetable/${storeDB}/search`, obj)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        printDebtorStatement: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser()
            let payload = context.getters.getGlobalDateRange;
            payload.id = id;
            payload.User = user.Username;
            return openUrl(franchiseApi + `account/Statement/${storeDB}?` + stringify(payload));
        },

        excelDebtorStatement: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let payload = context.getters.getGlobalDateRange;
            payload = queryStringify(payload);
            return openUrl(franchiseApi + `account/exportStatement/${id}/${storeDB}?` + payload);
        },

        exportCustomerList: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi + `account/exportCustomerList/${storeDB}?` + stringify(payload));
        },

        exportQuotesList: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return openUrl(franchiseApi + `account/exportQuotes/${storeDB}?` + stringify(payload));
        },


        makeDebtorPayment: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.User = user.Username;
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/makePayment`, payload)
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },

        creditAccountPayment: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            let obj = {user, id};
            return axios.post(backOfficeTomcatApi + `Payments/${storeDB}/returnAccountPayment`, obj)
                .catch((err) => errorHandler.tomcatError(err))
        },

        createQuote: async (context, debtorDetails = null) => {
            let user = await Storage.getCurrentUser();
            let mobileUser = await Storage.getMobileUser()
            let store = await Storage.getCurrentStore()

            let obj = {
                user: user.Username,
                mobileUser: mobileUser,
                store: store
            };
            if (debtorDetails) {
                obj.customerID = debtorDetails.customerID
                obj.currency = debtorDetails.currency;
                obj.debtors = debtorDetails.debtors
                obj.quoteID = debtorDetails.quoteID
            }
            obj = JSON.stringify(obj);
            obj = window.btoa(obj);
            // return openUrl(`http://192.168.1.166:8082/?quoteData=` + obj);

            return openUrl(`https://dsr.abc.co.zm?quoteData=` + obj);
        },
        convertQuoteToInvoice: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            return Swal.fire({
                icon: `question`,
                title: `Reference`,
                input: `text`,
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async (inputValue) => {
                    let payload = {
                        user: user.Username,
                        saleNumber: saleNumber,
                        reference: inputValue,
                    }
                    return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/convertQuoteToInvoice`, payload)
                        .then(({data}) => data.response.invoice)
                        .catch((err) => errorHandler.tomcatError(err))
                }
            }).then(result => {
                if (result.isConfirmed) {
                    return result.value;
                }
                return false;
            });
        },

        exportQuoteToInvoice: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            payload = {
                user,
                ...payload
            };
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/convertQuoteToInvoice`, payload)
                .then(({data}) => {
                    context.dispatch(`printInvoice`,{
                        invoice : data.response.invoice,
                        reprint : false,
                    });
                })
                .catch((err) => errorHandler.tomcatError(err))

        },

        creditEntireInvoice: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            payload.user = user.Username;
            payload.PC = `Online`;
            $utils.showLoading()
            return axios.post(onlineSalesTomcatApi + `invoice/${storeDB}/creditEntireAccountInvoice`, payload)
                .then(({data}) => {
                    data.response.invoice
                    $utils.hideLoading()
                })
                .catch(err => {
                    errorHandler.tomcatError(err)
                    $utils.hideLoading()
                })
        },

        printAccountPayment: async (context, id) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let obj = {
                PaymentID: id,
                user: user.Username,
            }
            obj = queryStringify(obj);
            return openUrl(franchiseApi + `AccPayments/print/${storeDB}?` + obj);
        },

        getTotalOwed: async () => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `debtor/${storeDB}/getTotalOwed`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        getQuotedTotal: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let obj = context.getters.getGlobalDateRange;
            let user = await Storage.getCurrentUser();
            obj.User = user.Username;
            return axios.get(backOfficeTomcatApi + `debtor/${storeDB}/getQuotedTotal`, {params: obj})
                .then(({data}) => data).catch((err) => errorHandler.tomcatError(err))
        },
        deleteQuote: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.delete(serverApi + `tables/${storeDB}/void/${saleNumber}`)
                .catch((err) => errorHandler.tomcatError(err))
        },
        deleteDeliveryNote: async (context, saleNumber) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            user = user.Username;
            return axios.delete(backOfficeTomcatApi + `deliveryNotes/${storeDB}/${saleNumber}?user=${user}`)
                .catch((err) => errorHandler.tomcatError(err))
        },
         getAllQuotes: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let obj = {
                ...context.getters.getGlobalDateRange,
                ...payload,
                User: !payload.User ? user.Username : payload.User,
            };
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/getAllQuotes`, obj)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getAllQuotes2: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            let obj = {
                ...context.getters.getGlobalDateRange,
                ...payload,
                User: !payload.User ? user.Username : payload.User,
            };
            return axios.post(backOfficeTomcatApi + `debtor/${storeDB}/searchAllQuotes`, obj)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        async getAllInvoices(context, payload) {
            let store = await Storage.getCurrentStoreDB();
            payload = {
                ...context.getters.getGlobalDateRange,
                ...payload
            }
            return axios.post(backOfficeTomcatApi + `other/${store}/searchSales`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
    }
}
