<template>
  <div>
    <button type="button" id="poActionsBtn" class="btn btn-primary" data-bs-toggle="modal" :data-bs-target="`#poActions-${purchaseOrder.orderNum}`">
      <font-awesome-icon icon="pen-to-square" />
      View More
    </button>
    <custom-modal :title="`Send Purchase Order ${purchaseOrder.orderNum}`" ref="purchaseOrderEmail" size="lg" :is-form="true" @submit="sendDocs">
      <div class="d-flex flex-column">
        <div v-for="(input, index) in additionalInputs" :key="index" class="mb-3">
          <label class="w-100">
            Email {{ index + 1 }}
            <a class="btn btn-sm btn-outline-primary mx-2" @click="addInput"
               v-if="index === 0 && additionalInputs.length < 5">
              <font-awesome-icon icon="plus"/>
            </a>

            <a class="btn btn-sm btn-outline-danger" @click="removeInput(index)" v-if="index !== 0">
              <font-awesome-icon icon="minus"/>
            </a>
            <input v-model="additionalInputs[index]" type="email" class="form-control" required
                   :placeholder="`Email ${index + 1}`">
          </label>
        </div>
        <div class="d-flex flex-column mb-2">

        </div>
        <div class="mb-3">
          <label class="w-100">
            Additional Notes
            <textarea class="form-control" v-model="form.additionalNote" rows="7" placeholder="Add notes"></textarea>
          </label>
        </div>
      </div>
    </custom-modal>

    <div class="modal fade" :id="`poActions-${purchaseOrder.orderNum}`" tabindex="-1" :aria-labelledby="`poActions-${purchaseOrder.orderNum}Label`" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="`poActions-${purchaseOrder.orderNum}Label`">Purchase Order No. : {{ purchaseOrder.orderNum }} </h1>
            <button type="button" :id="`poActions-${purchaseOrder.orderNum}-close`"  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <b>Supplier : {{ purchaseOrder.supplier }}</b>
            Total : {{ $utils.formatCurrency(purchaseOrder.Total , 2 , purchaseOrder.Symbol) }}
            <div class="d-flex flex-column">
              <button class="btn my-2 btn-light" @click="printPO">
                <font-awesome-icon icon="print" />
                Print
              </button>

              <button class="btn my-2 btn-info" @click="emailPO">
                <font-awesome-icon icon="envelope" class="me-2"/>
                Send Email
              </button>

              <button class="btn my-2 btn-info" v-if="!purchaseOrder.processed && !purchaseOrder.approved" @click="editPO(false)">
                <font-awesome-icon icon="pen-to-square" />
                Edit Purchase Order Details
              </button>

              <button class="btn my-2 btn-outline-primary" v-if="!purchaseOrder.approved" @click="approvePO">
                <font-awesome-icon icon="fingerprint" />
                Approve Purchase Order
              </button>

              <button class="btn my-2 btn-outline-success" v-if="!purchaseOrder.processed && purchaseOrder.approved" @click="view">
                <font-awesome-icon icon="check" />
                GRV
              </button>

              <button class="btn my-2 btn-danger" v-if="!purchaseOrder.processed" @click="DeletePurchaseOrder">
                <font-awesome-icon icon="trash" />
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomModal from "@/components/layout/utils/CustomModal";
export default {
  name: "PurchaseOrderOptions",
  components: {CustomModal},
  data() {
    return {
      form: {
        additionalNote : ``
      },
      additionalInputs: [``]
    };
  },
  props: {
    purchaseOrder : {
      type : Object,
      required : true
    }
  },
  methods : {
    addInput() {
      if (this.additionalInputs.length >= 5) return responses.showWarning(`Max emails reached!`)
      this.additionalInputs.push('');
    },
    removeInput(index) {
      this.additionalInputs.splice(index, 1);
    },

    emailPO(){
      this.form.additionalNote = `Purchase Order No. ${this.purchaseOrder.orderNum}\n`
      +`Supplier : ${this.purchaseOrder.supplier}\n`
      +`Description/Reference : ${this.purchaseOrder.description}\n`
      +`Created By : ${this.purchaseOrder.user}\n`
      +`Created on : ${this.purchaseOrder.created}\n`
      +`Expected Delivery Date : ${this.purchaseOrder.delivery}\n`
      +`Purchase Total : ${$utils.formatCurrency(this.purchaseOrder.Total,2,this.purchaseOrder.Symbol)}`;
      return this.$refs.purchaseOrderEmail.openModal();
    },
    printPO(){
      return this.$store.dispatch(`printPurchaseOrder`,this.purchaseOrder.orderNum);
    },

    sendDocs() {
      let obj = {
        emails: this.additionalInputs,
        id: this.purchaseOrder.orderNum,
        documentType: "purchaseOrder",
        additionalNote : this.form.additionalNote
      }
      this.$utils.showLoading()
      return this.$store.dispatch(`sendEmailDocument`, obj)
          .then(() => {
            let email = ``;
            this.additionalInputs = [email];
            this.form.additionalNotes = ``;
            responses.showInfo(`Email(s) Send Successfully`)
          }).finally(() => this.$utils.hideLoading())
    },

    editPO(isGRV){
      let orderNum = this.purchaseOrder.orderNum;  // Capture the orderNum
      let supplier = this.purchaseOrder.supplierCode
      console.log(supplier, "help")
      return this.$router.push({
        name: 'editItemsReceived',
        query : { isGRV , supplier ,orderNum  } })
    },
    approvePO(){
      this.$utils.showLoading();
      return this.$store.dispatch(`approvePurchaseOrder`,this.purchaseOrder.orderNum)
          .then(()=> {
            this.$emit(`updated`)
            this.closeModal();
          }).finally(()=>this.$utils.hideLoading())

    },
    closeModal(){
      document.getElementById(`poActions-${this.purchaseOrder.orderNum}-close`).click();
    },
    view() {
      let purchaseOrder = this.purchaseOrder;
      this.$utils.showLoading()
      return this.$store.dispatch(`checkGateEntrySetting`)
          .then(forceGateEntry => {
            if (forceGateEntry) {
              if (purchaseOrder.gateEntry === 0 || !purchaseOrder.gateEntry) {
                this.closeModal();
                return responses.showWarning(`Purchase Order. ${purchaseOrder.orderNum} Not Processed at Gate Entry`)
              }
              return this.$router.push({
                name: `view-gate-entry`,
                query: {
                  gateEntryID: purchaseOrder.gateEntry,
                  supplier: purchaseOrder.supplierCode,
                  orderNumber: purchaseOrder.orderNum,
                }
              })
            }
            return this.$router.push({
              name: 'stock-receive-items',
              query: {
                isGRV: true,
                supplier: purchaseOrder.supplierCode,
                orderNumber: purchaseOrder.orderNum,
                isConvert: true
              }
            })
          }).finally(() => this.$utils.hideLoading())
    },

    async DeletePurchaseOrder(){
      let orderNumber = this.purchaseOrder.orderNum;
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'viewPurchaseOrder');
      if (!isAllowed) return;
      return Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this purchase order!",
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        buttonsStyling: false, // Disabling default button styling
        showLoaderOnConfirm : true,
        customClass: {
          confirmButton: "btn btn-danger mx-2", // Custom styling for confirm button
          cancelButton: "btn btn-secondary", // Custom styling for cancel button
        },

        // dangerMode: true,
        preConfirm:()=> {
          return this.$store.dispatch(`deletePurchaseOrder`,orderNumber)
              .then(() => {
                this.closeModal();
                this.$emit(`updated`)
              })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          return responses.showInfo(`Done`)
        }
      });
    },
  }
}
</script>

<style scoped>

</style>