<template>
  <page-with-back-btn title="All Stores">
    <RangeSelector :submit-method="SearchDevices"/>

    <div class="card m-3 ">
      <div class="card-header text-primary">
        <b style="text-underline: black"> Devices</b>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#devicecount">Reset Devices</button>
        </div>
      </div>
      <div class="modal fade" id="devicecount" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
           aria-labelledby="devicecountLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="devicecountLabel">Logged in Devices</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-2">

                <label for="store">Remaining Resets : {{deviceCountData.remainingResets}} </label>
                <p></p>
                <p></p>
                <label for="store">Resets done this year : {{deviceCountData.resets}} </label>

              </div>

              <button class="lyt-soft-btn h-50 w-100" @click="devicecount2">Reset Device COUNT</button>
            </div>.
            <div class="modal-footer">
              <button type="button" id="devicecountClose" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <b-tabs v-model="activeTab">
          <b-tab title="Devices" @click="setActiveTab(0)">
            <vue-good-table :search-options="{ enabled: true }" :columns="columns" :rows="tickets2"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'action'">
                  <a class="btn btn-primary" @click="updateDevice(props.row)">
                    <font-awesome-icon :icon="['fas', 'edit']" /> Edit
                  </a>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
               </span>
              </template>
            </vue-good-table>
          </b-tab>
          <b-tab title="ResetHistory" @click="SearchDevices">
            <vue-good-table :search-options="{ enabled: true }" :columns="columns2" :rows="tickets"
                            :pagination-options="pageOptions" theme="polar-bear" styleClass="vgt-table condensed">
              <template slot="table-row" slot-scope="props">


              </template>
            </vue-good-table>
          </b-tab>
        </b-tabs>
      </div>



<!-- edit pc name-->
      <button id="modal-btn" type="button" style="display: none" data-bs-toggle="modal"
              data-bs-target="#editPCModal"></button>
      <div class="modal fade" id="editPCModal" data-bs-backdrop="static" data-bs-keyboard="false"
           tabindex="-1"
           aria-labelledby="editPCModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h6 class="modal-title my-2" id="editPCModal" >
                Update PC Name
              </h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeEditPCModal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

              <form @submit.prevent="updatePCName">
                  <div class="">
                    <label for="Description">
                      PC Name
                    </label>
                    <input type="text" class="form-control" v-model="form.alias" id="Description"
                           placeholder="Edit PC Name" required>
                  </div>

                <div class="modal-footer">
                  <button type="submit" class="primary-button w-100">
                    <font-awesome-icon :icon="['fas', 'save']" /> Save
                  </button>
                </div>

              </form>
            </div>
          </div>

        </div>

      </div>

    </div>
  </page-with-back-btn>
</template>

<script>
import axios from 'axios';
import FullPage from "@/components/layout/utils/FullPage.vue";
import RangeSelector from "@/components/layout/utils/RangeSelector";
import FloatingAddButton from "@/components/layout/utils/FloatingAddButton";
import {Storage} from "@/Services/Storage";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn.vue";
import {Modal} from "bootstrap";

export default {
  name: "Tickets",
  components: {PageWithBackBtn, FloatingAddButton, RangeSelector },
  data() {
    return {
      deviceCountData: [],
      id: this.$route.query.id,
      activeTab: 0, // Default to 'open' tab, set to number
      columns: [
        {label: "ID", field: "PCName"},
        {label: "PC Name", field: "alias"},
        {label: "Last Registered Date", field: "last_reg_date"},
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      columns2: [
        {label: "Reset By", field: "user" },
         {label: "Date", field: "date"},
        // {
        //   field: `action`,
        //   label: `Action`
        // },
      ],
      form: {
        alias: null,
      },
      selectedItem: null,
      pageOptions,
      datab: [], // This will contain all tickets, both open and closed
      tickets:[],
      tickets2:[]
    };
  },

  computed: {
    filteredTickets() {
      return this.tickets2;
    },

  },
  beforeMount() {
    this.getalldevices()
    this.getStoreDB()
    },
  methods: {

    async updateDevice(item){
      this.openEditModal();
      this.selectedItem = item;
    },
    closeEditModal() {
      const modalElement = document.getElementById('editPCModal');
      const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
      modalInstance.hide();
    },
    async updatePCName() {
      try {
        if(!this.selectedItem) return;
        let data = {};

        console.log("this.selectedItem", this.selectedItem);

        data.alias = this.form.alias
        data.deviceID = this.selectedItem?.PCName;
        this.$utils.showLoading();
        return axios
            .post(
                subscriptionApi + `devices/updateAlias`, data)
            .then((res) => {
              responses.showSuccess("PC name updated successfully");
              this.$utils.hideLoading();
              this.getalldevices();
              // close modal
              this.closeEditModal();
            })
            .catch((err) => {
              this.$utils.hideLoading()
              errorHandler.tomcatError(err)
            });
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },
    async devicecount() {
      this.$utils.showLoading()
      try {
        let database = this.datab.Database
         const response = await axios.get(subscriptionApi  + `devices/getResetStatusByStore?DB=${database}`);
        this.deviceCountData = response.data; // Update the data property with the fetched data
        console.log("Fetched data:", this.deviceCountData); // Log for debugging
      } catch (error) {
        console.error("Error fetching device count:", error);
      } finally{
        this.$utils.hideLoading()
      }
    },
    async devicecount2() {
      this.$utils.showLoading();
      try {
        let database = this.datab.Database

        this.user = await Storage.getMobileUser();
        this.storeDB = await Storage.getCurrentStoreDB();

        // Format the username to include {Client}

        await axios
            .post(subscriptionApi + "devices/resetDevices", {
              user: this.user.email, // Pass the formatted username
              DB: database,
            })
            .then((response) => {
              console.log("Fetched data:", response.data); // Log the actual response data

              // Show success message
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Device reset was successful!",
                timer: 2000, // 2 seconds
                showConfirmButton: false,
              }).then(() => {
                // Reload the page after success
                // location.reload();
              });
            })
            .catch((err) => {
              // Call your error handler
              errorHandler.tomcatError(err);

              // Show SweetAlert for the error
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "An error occurred while resetting devices.",
              });
            });
      } finally {
        this.$utils.hideLoading();
      }
    },
    async getStoreDB() {
      try {
        this.$utils.showLoading();
        let id = this.$route.params.id; // Use params instead of query
        console.log(this.id, "this is the info");

        const response = await axios.get(subscriptionTomcatApi + `customer/getStoreByID/${this.id}`);

        this.$utils.hideLoading();
        this.datab = response.data;
        await this.SearchDevices();
        await this.devicecount();
       } catch (error) {
        this.$utils.hideLoading();

        return errorHandler.tomcatError(error);
      }
    },
    async getalldevices() {
      try {
        this.$utils.showLoading();
        let id = this.$route.params.id; // Use params instead of query
        console.log(this.id, "this is the info");

        const response = await axios.get(subscriptionTomcatApi + `devices/getByStore/${this.id}`);

        this.$utils.hideLoading();
        this.tickets2 = response.data;
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },
    async SearchDevices() {
      try {
         let config = {
          params: {
            ...this.$store.getters.getGlobalDateRange,
            },
        };

        this.$utils.showLoading();
        let database = this.datab.Database
         const response = await axios.get(subscriptionTomcatApi + `devices/getResetInfoByStore/${database}`, config);
        this.$utils.hideLoading();
        this.tickets = response.data;
      } catch (error) {
        this.$utils.hideLoading();
        return errorHandler.tomcatError(error);
      }
    },


    setActiveTab(tab) {
      this.activeTab = tab;
      this.SearchDevices();
    },
    viewTicket(id) {
      let ticketid = id;
      localStorage.setItem("ticketID", ticketid); // Store the ticket ID
    },

    async openEditModal(){
      document.getElementById(`modal-btn`).click();
    },

   },

};
</script>

<style scoped>
/* Add any necessary styles here */
.text-sm {
  font-size: 13px;
}
</style>