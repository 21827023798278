<template>
  <page-with-back-btn title="Franchise Roles">
    <div class="info">
      <div class="row">
        <h4 class="text-center text-secondary">
          Create Role
          <button type="button" class="btn btn-primary mx-2" @click="createRole">
            <font-awesome-icon icon="fa-solid fa-plus" />
          </button>
        </h4>
      </div>
      <h4 class="text-center text-secondary">
        Select Role
      </h4>
      <div class="container">
        <div class="row">
          <div

              v-for="(group, index) in Roles"
              :key="index"          >
            <div class="security-card row d-flex justify-content-center btn btn-primary mb-2 p-3 text-center" @click="editGroup(group)">
              {{ group.role }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-with-back-btn>
</template>

<script>
import FullPage from "@/components/layout/utils/FullPage.vue";
import PageWithBackBtn from "@/components/layout/utils/PageWithBackBtn.vue";
import {Storage} from "@/Services/Storage";
import axios from "axios";

export default {
  name: "FranchiseSecurity",
  components: { PageWithBackBtn, FullPage },
  beforeMount() {
    this.getAllSecurityGroups();
  },
  computed: {
    loading() {
      return this.$store.getters.getLoadingState;
    },
    Roles() {
      return this.$store.getters.getFranchisePermissiions;
    }
  },
  methods: {
    getAllSecurityGroups() {
      this.$utils.showLoading();
      return this.$store.dispatch('getAllFranchiseSecurityGroups')
          .finally(() => this.$utils.hideLoading());
    },
    editGroup(group) {

      return this.$router.push({
        name: 'FranchiseEmployeeType',
        query: { group: group.id },
        params: { group: group }
      });
    },
    async createRole() {
         return Swal.fire({
          titleText :`Security Group Name?`,
          input: 'text',
          inputAutoTrim: true,
          showCancelButton: true,
          confirmButtonText: 'Save',
          showLoaderOnConfirm: true,
          preConfirm: async (inputValue) => {
            let DB = await Storage.getAllStores();
            let Group = DB[0].Group;
            if (!inputValue) return Swal.showValidationMessage(`Role Name Required`);
            return axios.post(backoffice + `api/roles`, {
              role: inputValue,
              group: Group
            }).catch(error => {
                  let err = errorHandler.tomcatError(error, true)
                  Swal.showValidationMessage(`Request failed: ${err}`);
                })
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) responses.showSuccess(`Security Group Created Successfully`)
           this.getAllSecurityGroups();

         })
      },    }


};
</script>

<style scoped>
.security-card {
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.security-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
