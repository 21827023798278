import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {Storage} from "@/Services/Storage";
import router from "../../../router";

Vue.use(Vuex);

export default {
    name: 'Securities',
    state: {
        securityGroups: [],
        FranchisePermissions: [],
        securities : []
    },
    getters: {
        getFranchisePermissiions: (state) => state.FranchisePermissions,
        getSecurityGroups: (state) => state.securityGroups,
        getSecurities: (state) => state.securities
    },
    mutations: {
        setFranchisePermissions: (state, payload) => state.FranchisePermissions = payload,
        setSecurityGroups: (state, payload) => state.securityGroups = payload,
        setAllSecurities: async (state, data) => {
            if (!data) data = await Storage.getAllSecurities();
            else await Storage.setAllSecurities(data);
            state.securities = data;
        },
    },
    actions: {
        getAllSecurityGroups: async (context) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.get(OnlineserverApi + `${DB}/getAllSecurityGroups`)
                .then(({data}) => context.commit('setSecurityGroups', data))
        },
        getAllFranchiseSecurityGroups: async (context) => {
            let DB = await Storage.getAllStores();
            let Group = DB[0].Group;
            console.log(DB)
            return axios.get(backoffice + `api/roles?group=${Group}`)
                .then(({data}) => context.commit('setFranchisePermissions', data))
        },
        async getAllSecurities(context) {
            let store = await Storage.getCurrentStoreDB();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `empsecurity/${store}/getAll`)
                .then(({data}) => context.commit(`setAllSecurities`, data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        getSecuritiesByType: async (context, payload) => {
            console.log("payload", payload)
            let DB = await Storage.getCurrentStoreDB();
            return axios.get(serverApi + `${DB}/getSecuritiesByType?type=${payload.type}&groupName=${payload.groupName}`, ).then(({data}) => data)
        },

        updateSecurity: async (context, payload) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.put(serverApi + `${DB}/update?groupName=${payload.groupName}&user=${payload.user}`, payload.securities)
                .then(() => router.push({name: 'employee-security'}))
                .catch(err => errorHandler.tomcatError(err))
        },

        getAutoFloatByGroup: async (context, payload) => {
            let DB = await Storage.getCurrentStoreDB();
            return axios.get(franchiseTomcatApi + `empsecurity/${DB}/getAutoFloatByGroup?group=`+payload)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        setAutoFloatToGroup: async (context, payload) => {
            let createdBy = await Storage.getCurrentUser();
            createdBy = createdBy.Username;
            payload = { ...payload , createdBy : createdBy }
            let DB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `empsecurity/${DB}/updateAutoFloat`, payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        createSecurityGroup: async (context) => {
            let DB = await Storage.getCurrentStoreDB();
            let user = await Storage.getCurrentUser();
            return Swal.fire({
                titleText :`Security Group Name?`,
                input: 'text',
                inputAutoTrim: true,
                showCancelButton: true,
                confirmButtonText: 'Save',
                showLoaderOnConfirm: true,
                preConfirm: (inputValue) => {
                    if (!inputValue) return Swal.showValidationMessage(`Security Group Name Required`);
                    return axios.post(serverApi + `${DB}/create?groupName=${inputValue}&user=${user.Username}` ).then(() => context.dispatch(`getAllSecurityGroups`))
                        .catch(error => {
                            let err = errorHandler.tomcatError(error, true)
                            Swal.showValidationMessage(`Request failed: ${err}`);
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) responses.showSuccess(`Security Group Created Successfully`)
            })
        },



        validateSecurityPermissionNoPrompt: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            return group[security];
        },
        validateSecurityPermission: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            console.log(group,"here")
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            if (!group[security]) {
                return Swal.fire({
                    title: `User not Allowed to ${security}`,
                    icon : "warning",
                    text : `Input supervisor password \n To Allow ${user.Username} to ${security}`,
                    input: "password",
                    showCloseButton : true,
                    showCancelButton: true,
                    confirmButtonText: "Authorize",
                    showLoaderOnConfirm : false,
                    preConfirm: async (password) => {
                        let users = await Storage.getAllUsers();
                        console.log(users)

                        let supervisor = users.find(user => {
                            if (!user.password) {
                                console.warn(`User ${user.Name || "unknown"} does not have a Password property.`);
                                return false;
                            }
                            return user.password.toLowerCase() === password.toLowerCase();
                        });
                        if (!supervisor) {
                            Swal.showValidationMessage(`Incorrect password`)
                            return false;
                        }
                        let supervisorType = supervisor.Type;
                        let supervisorGroup = securities.find(sec => sec.GroupName.toLowerCase() === supervisorType.toLowerCase());
                        if (!supervisorGroup[security]) {
                            Swal.showValidationMessage(`User ${supervisor.Username} not allowed to authorize ${security}`)
                            return false;
                        }

                        return {
                            allowed : true,
                            supervisor : supervisor.Username,
                            cashier : user.Username,
                        };
                    },

                }).then(async (result) => {

                    if (result.isConfirmed) {
                        let value = result.value;
                        if (value.allowed === true){
                            let storeDB = await Storage.getCurrentStoreDB();
                            axios.post(serverApi+`supervisorOverrides/${storeDB}`,{
                                Supervisor : value.supervisor,
                                Cashier : value.cashier,
                                Reason : security,
                            })
                        }
                        return value;
                    }
                    return false;
                });

            }
            if (security.toString().toLowerCase().includes(`void`)){
                return {supervisor : user.Username}
            }
            return true;
        },


    }
};
