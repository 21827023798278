<template>
  <div class="error-container">
    <div class="error-card">
      <svg class="error-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="12" cy="12" r="10" />
        <line x1="15" y1="9" x2="9" y2="15" />
        <line x1="9" y1="9" x2="15" y2="15" />
      </svg>
      <h1>License Purchase Failed</h1>
      <p>We encountered an error while adding your new license. Please try again.</p>
      <button class="btn" @click="retry">Retry Process</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    retry() {
      this.$router.push('/StoreManagement');
    },
  },
};
</script>

<style scoped>
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fdf2f2;
}

.error-card {
  text-align: center;
  background: #fff;
  padding: 3rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.error-icon {
  width: 80px;
  height: 80px;
  color: #e53e3e;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2rem;
  color: #c53030;
  margin-bottom: 1rem;
}

p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 2rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  background: #e53e3e;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.btn:hover {
  background: #c53030;
}
</style>
