<template>
  <div class="drawer">
    <div class="user-avatar">
      <div v-if="curUser" class="avatar-details">
        <h5>{{ curUser.Username }}</h5>
        <p>{{ curUser.Type }}</p>
        <p class="fw-bolder">{{ store }}</p>
      </div>
    </div>
    <div class="accordion menu" id="accordionPanelsStayOpenExample">
      <div class="accordion-item" v-if="isUpdateAvailable">
        <a @click="updateApp">
          <font-awesome-icon class="icon text-info" icon="fa-solid fa-exclamation-circle"/>
          <div class="d-flex flex-column text-center">
            Update Is Available
            <span class="text-sm" @click="updateApp">Click to Update</span>
          </div>
        </a>
      </div>
      <div class="box-container mt-2" v-if="isSmartInvoiceLive && shouldSmartInvoiceSignUp">
        <div class="box red-background-blink m-2">
          <a class="route-btn w-100 bg-transparent text-white"> Smart Invoicing Sign up </a>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
              :class="{ 'accordion-button': true, 'collapsed': !isStockOpen }"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#panelsStayOpen-collapseOne"
              aria-expanded="false"
              aria-controls="panelsStayOpen-collapseOne"
              @click="toggleAccordion('isStockOpen')"
              :aria-expanded="isStockOpen ? 'true' : 'false'"
          >
            Store Management
          </button>
        </h2>
        <div id="panelsStayOpen-collapseOne"
             class="accordion-collapse collapse"
             :class="{ 'show': isStockOpen }"
             aria-labelledby="panelsStayOpen-headingOne"
             v-show="isStockOpen"
        >
          <div class="accordion-body">
            <router-link @click.native="back" class="route-btn w-100 bg-transparent" :to="{ name : 'stock' }">
              <font-awesome-icon class="icon" icon="fa-solid fa-chart-simple"/>
              Login to Store
            </router-link>

            <a @click="addStock" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
              Create a Store/StoreRoom
            </a>

            <a @click="editStock" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-pen-to-square"/>
              Update Store Details
            </a>
            <a @click="deleteStock" class="route-btn w-100 bg-transparent">

              <font-awesome-icon class="text-danger icon" icon="fa-license"/>
              Buys License
            </a>
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
              :class="{ 'accordion-button': true,  'collapsed': !isCustomerOpen }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                  @click="toggleAccordion('isCustomerOpen')"
                  :aria-expanded="isCustomerOpen ? 'true' : 'false'"
          >
            Reports Platform
          </button>
        </h2>
        <div
            id="panelsStayOpen-collapseTwo"
            class="accordion-collapse collapse"
            :class="{ 'show': isCustomerOpen }"
            aria-labelledby="panelsStayOpen-headingTwo"
            v-show="isCustomerOpen"
        >
          <div class="accordion-body">
            <a @click="franchiseDebtors" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="business-time"/>
              Dashboard
            </a>

            <a @click="createCustomer" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
              Stock
            </a>

            <a @click="editCustomer" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-pen-to-square"/>
              Franchise
            </a>

            <a @click="customerQuotes" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-money-check"/>
              CashUp
            </a>

            <a @click="viewInvoices" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-money-check"/>
              VAT
            </a>

            <a @click="pointOfPayment" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-money-check"/>
              Account
            </a>
            <a  @click="DeliveryNotes" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-money-check"/>
              Sales
            </a>
            <a  @click="Layby" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-money-check"/>
              Finance
            </a>
            <a v-if="isLoyaltyActivated" @click="Loyalty" class="route-btn w-100 bg-transparent">
              <!--              <router-link @click.native="back" :to="{ name : 'LoyaltyCustomers' }">-->
              <font-awesome-icon class="icon" icon="heart-circle-check"/>
              Supervisor
            </a>
          </div>
        </div>
      </div>


      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
                  :class="{ 'accordion-button': true, 'collapsed': !isSupplierOpen }"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                  @click="toggleAccordion('isSupplierOpen')"
                  :aria-expanded="isSupplierOpen ? 'true' : 'false'"

          >
            Franchise HR
          </button>
        </h2>
        <div id="panelsStayOpen-collapseThree"
             class="accordion-collapse collapse"
             :class="{ 'show': isSupplierOpen }"
             aria-labelledby="panelsStayOpen-headingThree"
             v-show="isSupplierOpen"
        >
          <div class="accordion-body">
            <a @click="createSuplier" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-plus"/>
              Create Franchise Employee
            </a>

            <a @click="editSuplier" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-pen-to-square"/>
              Edit Franchise Employee
            </a>


            <a @click="editSuplier" class="route-btn w-100 bg-transparent">
              <font-awesome-icon class="icon" icon="fa-solid fa-pen-to-square"/>
              Franchise Employee Security
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import {Storage} from "@/Services/Storage";
import CaptureExpense from "@/components/Finance/CaptureExpense.vue";
import axios from "axios";

export default {
  name: "NavigationDrawer",
  components: {CaptureExpense},
  props: {
    close: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      isStockOpen: false,
      isCustomerOpen:false,
      isTillAdminOpen: false,
      isAddonsOpen: false,
      isSupplierOpen:false,
      isFinanceOpen: false,
      isBillingOpen: false,
      isCreateEmplyOpen: false,
      isSupportOpen: false,
      isSignOutOpen: false
    };
  },
  watch: {
    isLoyaltyActivated(newValue) {
      // console.log('isLoyaltyActivated changed:', newValue);
    }
  },
  mounted() {
    // console.log('isLoyaltyActivated:', this.isLoyaltyActivated);
  },
  computed: {
    isLoyaltyActivated() {
      return this.$store.getters.getHasLoyalty;
    },
    loggedInSupportUser() {
      return localStorage.getItem("Supportlogged in user") || "No user logged in";
    },
    store() {
      let store = this.$store.getters.getCurrentStore;
      if (store) return store.StoreName;
      return "Unknown";
    },
    hasKazang() {
      return !!this.$store.getters.getKazangDetails;
    },
    curUser() {
      let user = this.$store.getters.getCurrentUser;
      if (user) return user;
      return {
        Username: 'Null',
        Type: 'Unknown',
      }
    },
    isUpdateAvailable() {
      return this.$store.getters.getUpdateAvailability;
    },
    shouldSmartInvoiceSignUp() {
      return !this.$store.getters.getSmartInvoicingStatus && !this.$store.getters.getDocSubmissionStatus;
    },
    isSmartInvoiceLive() {
      return this.$store.getters.getSmartInvoicingLive;
    },
  },
  methods: {
    toggleAccordion(section) {
      this[section] = !this[section];
    },
    back() {
      if (this.close) {
        this.close();
      } else {
        console.log("Close not defined")
      }
    },

    //stock securities
    async addStock() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'addStock');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-create'});
      this.back()
    },
    async editStock() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'editStock');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-search'});
      this.back()
    },
    async exportSalesDump() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'SalesReport');
      if (!isAllowed) return;
      return this.$store.dispatch(`exportSalesDump`);
    },
    async manualLedgerEntry() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'manualEntry');
      if (!isAllowed) return;
      return this.$router.push({name: `create-manual-ledger-entry`});
    },
    async openVATReports() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'vatReports');
      if (!isAllowed) return;
      let obj = {
        name: 'VATScreen',
        params: {
          StoreDB: await Storage.getCurrentStoreDB()
        }
      };
      return this.$store.dispatch(`openFranchisePlatform`, obj);
    },
    async special() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Specail');
      if (!isAllowed) return;
      this.$router.push({name: 'specials'});
      this.back()
    },
    async deleteStock() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'deleteCode');
      if (isAllowed) {
        await this.$router.push({name: 'StockArchive'});
        return this.back();
      }
    },


    async massPriceEdit() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'massPriceEditor');
      if (!isAllowed) return;
      this.$router.push({name: 'mass-price-edit'});
      this.back()
    },
    async massUpdate() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'massUpdate');
      if (!isAllowed) return;
      this.$router.push({name: 'MassStockUpdate'});
      this.back()
    },

    async department() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Department');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-categories-search'});
      this.back()
    },

    async stockReceive() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'GRV');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-received-supplier'});
      this.back()
    },
    async viewPurchaseOrder() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'viewPurchaseOrder');
      if (!isAllowed) return;
      this.$router.push({name: 'view-purchase-orders'});
      this.back()
    },

    async production() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'MassProduction');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-production'});
      this.back()
    },
    async stockAdjustment() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Adjustment');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-adjustment'});
      this.back()
    },
    async stockTake() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'StockTake');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-take'});
      this.back()
    },
    async stockTransfer() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'franchiseSection');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-transfer'});
      this.back()
    },
    async stockConvert() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Adjustment');
      if (!isAllowed) return;
      this.$router.push({name: 'stock-conversion'});
      this.back()
    },
    async viewCurrency() {
      return this.$router.push({name: 'currency'});
    },

    async viewExpensecategories() {
      return this.$router.push({name: 'viewExpensecategories'});
    },

    async viewExpenseRecords() {
      return this.$router.push({name: 'viewExpenseRecords'});
    },

    async AssetCategories() {
      return this.$router.push({name: 'AssetCategories'});
    },

    async AssetRecords() {
      return this.$router.push({name: 'AssetRecords'});
    },
    viewSafes() {
      return this.$router.push({name: 'safe-search'});

    },
    //customer securities
    franchiseDebtors() {
      this.$router.push({name: 'main-debtors'});
      this.back()
    },

    async createCustomer() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'AddDeb');
      if (!isAllowed) return;
      await this.$router.push({name: 'debtor-create'});
      this.back()
    },

    async editCustomer() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'EditDeb');
      if (!isAllowed) return;
      await this.$router.push({name: 'debtor-search'});
      this.back()
    },
    async customerQuotes() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'LoadTable');
      if (!isAllowed) return;
      this.$router.push({name: 'quotes'});
      this.back()
    },
    viewInvoices() {
      this.$router.push({name: 'ViewInvoices'});
      this.back();
    },
    pointOfPayment() {
      this.$router.push({name: 'pointOfPayment'});
      this.back();
    },
    Layby(){
      this.$router.push({name: 'Layby'});
      this.back();
    },
    DeliveryNotes() {
      this.$router.push({name: 'DeliveryNotes'});
      this.back();
    },
    Loyalty() {
      this.$router.push({name: 'LoyaltyCustomers'});
      this.back();
    },

    //till admin securities
    async editFloat() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'floatSetup')
      if (!isAllowed) return;
      this.$router.push({name: 'Float-screen'})
      this.back()
    },
    async storeCashups() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'bofficeCashup')
      if (!isAllowed) return;
      this.$router.push({name: 'storeCashups'})
      this.back()
    },

    //suplier securities
    async createSuplier() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'AddCred')
      if (!isAllowed) return;
      this.$router.push({name: 'supplier-create'})
      this.back()
    },

    async editSuplier() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'EditCred')
      if (!isAllowed) return;
      this.$router.push({name: 'supplier-search'})
      this.back()
    },

//Finance securities
    async financeDep() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'financePanel')
      if (!isAllowed) return;
      this.$router.push({name: 'finance-select'})
      this.back()
    },
    async zedMobileSetup() {
      // Handle the Zed Mobile SetUp action here
      this.$router.push({name: 'ZedMobileSetup'});
    },
    // creating a ticket

    async ViewClosedTicket() {
      this.$router.push({name: 'ViewClosedTicket'})
      this.back()
    },
    async ViewOpenTicket() {
      this.$router.push({name: 'ViewOpenTicket'})
      this.back()
    },
    async TicketRoute() {
      this.$router.push({name: 'Create-Ticket'})
      this.back()
    },


    //HR SECURITIES
    async createEmp() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'CreateEmp')
      if (!isAllowed) return;
      this.$router.push({name: 'employee-create'})
    },
    async editEmp() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'editEmp')
      if (!isAllowed) return;
      this.$router.push({name: 'employee-search'})
    },
    async empSecurity() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'editEmp')
      if (!isAllowed) return;
      this.$router.push({name: 'employee-security'})
    },

    //Billing securities
    async subscription() {
      // let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, '')
      // if(!isAllowed) return;
      this.$router.push({name: 'subscription-accounts'})
    },
    async BillingQuotes() {
      // let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, '')
      // if(!isAllowed) return;
      this.$router.push({name: 'customer-quotes'})
    },
    Invoices() {
      this.$router.push({name: 'Invoices'});
      this.back();
    },

    async reports() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Reports')
      if (!isAllowed) return;
      this.$utils.showLoading()
      return this.$store.dispatch(`openFranchisePlatform`)
          .finally(() => {
            this.$utils.hideLoading()
            this.back();
          })
    },

    //settings securities
    async sysSettings() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'Settings')
      if (!isAllowed) return;
      this.$router.push({name: 'SystemSettings'})
    },
    lytsoftapps() {
      return this.$router.push({name: 'Lyt-SoftApps'})
    },
    smartInvoiceSignUp() {
      this.back();
      return this.$router.push({name: 'smart-invoicing-setup'})
    },
    updateApp() {
      this.$store.commit(`setUpdateAvailability`, false);
      return window.location.reload(true);
    },
    async userLogout() {
      try {
        const storeDB = await Storage.getCurrentStoreDB();
        let loggedUser = Storage.getItem("Supportlogged in user");

        if (!loggedUser) {
          loggedUser = await Storage.getCurrentUser();
          loggedUser = loggedUser.Name;// Fallback to fetch current user if not in local storage
        }

        const deviceID = await Storage.getDeviceID();

        const loginPayload = {
          username: loggedUser,
          platform: "OnlineBackOffice",
          pc: deviceID,
        };

        await axios.post(
            `${serverApi}supervisorOverrides/${storeDB}/recordLogout`,
            loginPayload
        );
      } catch (err) {
        console.error("Error during logout:", err);
      } finally {
        localStorage.removeItem("Supportlogged in user");
        this.$router.push({ name: 'userLogin' });
      }
    },

    async switchStore() {
      this.$utils.showLoading();

      try {
        const storeDB = await Storage.getCurrentStoreDB();
        let loggedUser = Storage.getItem("Supportlogged in user");

        if (!loggedUser) {
          loggedUser = await Storage.getCurrentUser(); // Fallback to fetch current user if not in local storage
          loggedUser = loggedUser.Name;// Fallback to fetch current user if not in local storage

        }

        const deviceID = await Storage.getDeviceID();

        const loginPayload = {
          username: loggedUser,
          platform: "OnlineBackOffice",
          pc: deviceID,
        };

        // Clear store state and navigate to login
        await this.$store.dispatch("clearStoreState");
        await this.$router.push({ name: "login" });

        // Record login after switching stores
        await axios.post(
            `${serverApi}supervisorOverrides/${storeDB}/recordLogout`,
            loginPayload
        );
      } catch (err) {
        localStorage.removeItem("Supportlogged in user");

        console.error("Error during store switch:", err);
      } finally {
        // Perform app updates and hide the loading spinner
        this.updateApp();
        this.$utils.hideLoading();
      }
    },



  }
}
</script>

<style lang="scss" scoped>
a {
  &:hover, &.a-exact-active {
    background: #f1f1f1;
    color: #3F8DCD;

    .icon {
      color: #3F8DCD;
    }
  }
}

.warning {
  border-radius: 50%;
  display: inline-flex; /* Ensures that the container can shrink to fit its content */
  align-items: center; /* Center the content vertically */
  justify-content: center; /* Center the content horizontally */
  box-shadow: 0 0 0 9px rgba(217, 193, 98, 0); /* Starting with transparent box-shadow */
  transform: scale(1);
  animation: pulse 2s infinite;
  transform-origin: center center; /* Ensures transform scales from the center */
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 9px rgba(217, 193, 98, 1); /* Fully opaque at start */
  }
}

.disabled {
  pointer-events: none;
  display: none;
}

.drawer {
  width: 100%;
  background: white;
  height: 100%;
  overflow-y: auto;

  .user-avatar {
    display: inline-flex;
    width: 100%;
    background: url('/src/assets/img/play-banner.png') fixed;
    background-size: cover;
    color: white;
    min-height: 140px;
    background-color: rgba(50, 70, 80, 0.7);
    background-blend-mode: soft-light;

    .avatar {
      background: #40434A;
      min-width: 80px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }

    .avatar-details {
      width: 100%;
      padding: 5px 5px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;

      h5, p {
        margin: 0;
      }

      p {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;

        &:nth-child(3) {
          color: #15356E;
          font-weight: 600;
        }
      }

    }
  }
}

.menu {
  text-align: center;

  span {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: bold;
  }
}

.last-upd {
  font-weight: bold;
}

.icon {
  margin-right: 10px;
  color: #606d77;
  font-size: 1.5rem;
}

.route-btn {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  text-decoration: none;
  color: #1c1c1c;
}

#footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.not-footer {
  margin-bottom: 100px;
}

.box-container {
  display: flex;
  justify-content: center; /* Center contents horizontally */
  margin-bottom: 10px; /* Adjust as needed */
}

.red-background-blink {
  background-color: red;
  animation: blink 1s infinite;
  padding: 10px 20px; /* Adjust height and width */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.box {
  display: inline-block;
}

.text-white {
  color: white;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.accordion-collapse {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.accordion-collapse.show {
  max-height: 500px;
}


</style>

